import React, { useEffect, useState } from 'react';
import backdrop from '../../assets/images/backdrop.webp';
import { CardInfo } from '../../components/Cards/CardInfo';
// import Next from '../../assets/icons/Next';
// import Previous from '../../assets/icons/Previous';
import withoutAuthProvider from '../../services/withoutAuthProvider';
import { SkeletonBlog } from '../../components/Skeleton/Skeleton';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const initialPaginate = {
  page: 1,
  total: 1,
};

export default function InfoCaready() {
  const [infoData, setInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const { push } = useHistory();

  const seeDetail = (slug) => push(`/info-caready-detail/${slug}`);

  const handlePageClick = (event) => {
    // console.log(event.selected);
    setPaginate((prev) => ({
      ...prev,
      page: event.selected + 1,
    }));
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getBlogs = async () => {
    setIsLoading(true);
    const params = {
      page: paginate.page,
      page_size: 12,
    };
    const response = await withoutAuthProvider.getDataWithoutAuth(
      '/api/blog',
      params
    );
    if (response.status === 200) {
      const res = response.data.data;
      setInfoData(res.data);
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      // console.log(response.response)
    }
  };

  useEffect(() => {
    scrollToTop();
    getBlogs().finally(() => setIsLoading(false));
  }, [paginate.page]);

  return (
    <div>
      <div
        className='cover-background position-relative'
        style={{ background: `url(${backdrop})` }}
      >
        <div className='background-overlay'></div>
        <div
          className='page-header'
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Dapatkan informasi terkini
          <br /> mengenai Caready!
        </div>
      </div>
      <div
        className='container'
        style={{ paddingTop: '80px', paddingBottom: '80px' }}
      >
        <div className='row'>
          {isLoading
            ? new Array(5).fill(2).map((el, idx) => (
                <div className='col-md-4 my-3' key={idx}>
                  <SkeletonBlog />
                </div>
              ))
            : infoData.map((info, idx) => (
                <div
                  className='col-md-4 my-3 cursor-pointer'
                  key={idx}
                  onClick={() => seeDetail(info?.slug)}
                >
                  <CardInfo
                    title={info?.title}
                    image={info?.image_url}
                    badges={info?.tags || []}
                    date={info?.created_at}
                  />
                </div>
              ))}
        </div>
        <div className='row'>
          <ReactPaginate
            breakLabel='...'
            nextLabel={'>'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={paginate.total}
            previousLabel='<'
            renderOnZeroPageCount={null}
            disabledClassName='disabled'
            containerClassName='pagination-container mt-3'
            activeClassName='active'
            pageClassName='pagination-item mx-1 px-2'
            breakClassName='pagination-item mx-1 px-2'
            nextClassName='pagination-item arrow mx-1 px-2'
            previousClassName='pagination-item arrow mx-1 px-2'
          />

          {/* <ul className="pagination-container mt-15">
                        <li className="pagination-item disabled arrow">
                            <Previous fill={"#D1D5DC"} />
                        </li>
                        <li className="pagination-item active">1</li>
                        <li className="pagination-item">2</li>
                        <li className="pagination-item">3</li>
                        <li className="pagination-item">4</li>
                        <li className="pagination-item arrow active">
                            <Next fill={"#C20102"} />
                        </li>
                    </ul> */}
        </div>
      </div>
    </div>
  );
}
