import React, { useState } from "react";
// import carUnit from '../../assets/images/car-unit.webp';
import SpecialNIPL from "../../assets/icons/SpecialNIPL";
import { format } from "date-fns";

import {
  defaultBlog,
  // defaultImageCar
} from "../../utils/defaultValue";
import PPNIcon from "../../assets/icons/PPNIcon";

const CardUnit = (props) => {
  const defaultFunc = () => {};
  const [TimeBid, setTimeBid] = useState(false);

  return (
    <div
      className={`card-unit cursor-pointer h-100 ${
        props.live === 1 ? "live" : ""
      }`}
      onClick={props.onClick || defaultFunc}
    >
      <div
        className="card-unit-header"
        style={
          props.pictures?.length > 0
            ? { background: `url("${props.pictures[0].image_url}")` }
            : { background: `url(${defaultBlog})` }
        }
      >
        {/* <div className="card-unit-header" style={(props.pictures?.length > 0) ? { background: `url(${carUnit})` } : { background: `url(${carUnit})` }}> */}
        <div className="overlay">
          <div className="card-unit-grade">Grade {props.grade || "-"}</div>
        </div>
      </div>
      <div className="unit-lot-wrapper">
        <div className="card-unit-lot">Lot {props?.lot_number}</div>
        {props.specialNIPL > 1 && (
          <div className="card-unit-lot c-orange">
            <span className="icon-nipl">
              <SpecialNIPL />
            </span>
            <span>Special NIPL</span>
          </div>
        )}
      </div>
      <div className="card-unit-body d-flex flex-column">
        <h5 className="card-unit-name">{props?.nama}</h5>
        <div className="card-unit-tag">
          {props?.tags
            ? props?.tags.map((data, i) => (
                <span key={"tag" + i}>{data.name}</span>
              ))
            : ""}
        </div>
        <div className="card-unit-price-detail">
          <div className="card-unit-price d-table">
            Rp {props.price ? props.price.toLocaleString() : "-"}
          </div>
          {props.ppn_include === 0 && (
            <div className="card-unit-ppn">
              <span className="icon-ppn mr-6">
                <PPNIcon />
              </span>
              PPN {props.ppn_buyer_fee}%
            </div>
          )}
        </div>

        {(props.unitType === "Motor" || props.unitType === "Mobil") && (
          <div className="card-unit-spec">
            <div className="card-unit-spec-detail">
              <span id="header">Tahun</span>
              <span id="detail">{props?.year || "-"}</span>
            </div>
            <div className="card-unit-spec-detail">
              <span id="header">Odometer</span>
              <span id="detail">
                {props?.odometer !== "null" ? `${props?.odometer} KM` : "-"}
              </span>
            </div>
          </div>
        )}

        <div className="card-unit-location d-table"></div>
        {props.auction !== undefined ? (
          <div className="card-unit-footer d-flex mt-auto footer-label">
            <div className="time-location">
              {props?.auction?.location_name.replace("Caready ", "")} -{" "}
              {props?.auction?.date_start && props?.auction?.time_start
                ? format(
                    new Date(`${props?.auction?.date_start}`),
                    "dd MMM yyyy,"
                  )
                : "-"}{" "}
              {props?.auction?.date_start && props?.auction?.time_start
                ? props?.auction?.time_start?.substr(0, 5)
                : "-"}
            </div>
            {TimeBid ? (
              <div className="metode blue">TIME BID</div>
            ) : props?.auction?.is_started ? (
              <div className="metode red">LIVE</div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CardUnit;
