import { useState, useEffect } from "react";
import SearchField from "../SearchField/SearchField";
// import carImage from '../../assets/images/car-unit.webp'
import { Row, Col } from "react-bootstrap";
import PriceTag from "../../assets/icons/PriceTag";
import { SkeletonProxyBid, SkeletonSearch } from "../Skeleton/Skeleton";
import withAuth from "../../services/withAuthProvider";
import toast from "react-hot-toast";
import { format } from 'date-fns';
import MoreButton from "../MoreButton/MoreButton";
import { defaultImageCar } from "../../utils/defaultValue";
import { useHistory } from "react-router-dom";

const ChildProxyBid = (props) => {
    console.log(props)
    return (
        <div className="card-transaksi">
            <div className='body'>
                <Row>
                    <Col md={6}>
                        <div className='d-flex mb-3 mb-md-0' style={{ borderRight: "1px solid #BEBFC2" }}>
                            <div>
                                <div className="unit-image" style={(props?.data?.stock_unit?.pictures?.length > 0) ? { position: "relative", float: "left", background: `url("${props?.data?.stock_unit?.pictures[0].image_url}")` } : { position: "relative", float: "left", background: `url(${defaultImageCar})` }}></div>
                            </div>
                            <div className="d-flex flex-column w-100 py-1">
                                <div className="border-bottom me-2 pb-1" style={{ marginLeft: "10px" }}>
                                    <div>
                                        <span className='value disable-copy'>
                                            <span className='badge bg-primary'>LOT-{props?.data?.auction?.auction_lane_name || ""}{props?.data?.stock_unit?.lot_number || ""}</span>
                                        </span>
                                    </div>
                                    <div className='unit-name mb-2' style={{ height: 'auto' }}>{props?.data?.stock_unit?.unit_name || props?.data?.stock_unit?.year + " " + props?.data?.stock_unit?.unit_maker?.name + " " + props?.data?.stock_unit?.unit_model?.name}</div>
                                    <span className="title">Harga Final</span><br />
                                    <span className="harga final">Rp {props?.data?.final_price ? Number(props?.data?.final_price).toLocaleString() : "-"}</span>
                                </div>
                                <div className="d-flex font-xs align-items-center py-2 px-2 justify-content-between">
                                    <div className="text-secondary fw-semibold">
                                        {props?.data?.auction?.location_name || ""} - {props?.data?.auction?.date_start && props?.data?.auction?.time_start ? format(new Date(`${props?.data?.auction?.date_start} ${props?.data?.auction?.time_start}`), 'dd MMM yyyy, HH:mm') : "-"}
                                    </div>
                                    {/* <div className="border rounded px-2 border-primary text-primary">
                                        Live
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='d-flex flex-column flex-md-row'>
                            <div style={{ flex: "1" }} className="d-flex flex-row flex-md-column w-100 justify-content-between mb-2">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="d-inline-block me-2 rounded-circle" style={{ width: '40px', height: '40px', backgroundColor: '#F9E6E6' }}>
                                        <div className="d-flex flex-center h-100">
                                            <PriceTag className="text-primary" />
                                        </div>
                                    </div>
                                    <span className="mb-0 fw-bold">NIPL - {props?.data?.nipl?.nipl || "-"}</span>
                                </div>
                                <div>
                                    <span className="title">Harga Penawaran</span><br />
                                    <span className="harga tagihan">Rp {props?.data?.offer_price ? Number(props?.data?.offer_price).toLocaleString() : "-"}</span><br />
                                </div>
                            </div>
                            <div className='button'>
                                <span className="btn-bayar cursor-pointer" onClick={props.onClick}>Live Auction</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const initialPaginate = {
    page: 1,
    total: 1
}

const ProxyBidContent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [proxyBid, setProxyBid] = useState([]);
    const [paginate, setPaginate] = useState({ ...initialPaginate });
    const [search, setSearch] = useState("");
    const { push } = useHistory();

    const loadMore = () => {
        setPaginate(prev => ({
            ...prev,
            page: prev.page + 1,
        }))
    }

    const getDataproxyBid = async () => {
        const params = {
            page: paginate.page,
            page_size: 5,
            search: search
        }

        const response = await withAuth.getDataWithAuth('/api/proxy-bid', params)
        if (response.status === 200) {
            const res = response.data.data;
            setProxyBid((last_data) => [
                ...last_data,
                ...res.data,
            ]);
            setPaginate(prev => ({
                ...prev,
                total: res.last_page,
            }))
            setIsLoading(false);
        } else {
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    useEffect(() => {
        getDataproxyBid().finally(() => setIsLoading(false));
    }, [paginate.page])

    useEffect(() => {
        if (search.length >= 3) {

            const reloadData = async () => {
                const params = {
                    page: 1,
                    page_size: 5,
                    search: search
                }

                const response = await withAuth.getDataWithAuth('/api/proxy-bid', params)
                if (response.status === 200) {
                    const res = response.data.data;
                    setProxyBid(res.data);

                    setPaginate(prev => ({
                        ...prev,
                        total: res.last_page,
                    }))
                    setIsLoading(false);
                } else {
                    toast.error(response.data.message, {
                        id: 'error-message',
                        duration: 3000
                    });
                }
            };

            reloadData();

        }
    }, [search])

    return (
        <>
            <div className='tab-content-caready border-bottom'>
                <h3 className='title mb-0'>Proxy Bid</h3>
            </div>
            <div className="tab-content-caready">
                {
                    isLoading ?
                        <SkeletonSearch /> :
                        <SearchField placeholder="Cari di Proxy Bid" onChange={(e) => setSearch(e.target.value)} />
                }
                <div className="mt-3 d-flex flex-column" style={{ gap: '1rem' }}>
                    {
                        isLoading ?
                            new Array(3).fill(1).map((el, idx) => (
                                <div key={idx}>
                                    <SkeletonProxyBid />
                                </div>
                            )) :
                            proxyBid.map((el, idx) => (
                                <div key={idx}>
                                    <ChildProxyBid data={el} onClick={() => push(`/live-auction/${el?.auction?.id}`)} />
                                </div>
                            ))
                    }
                    {
                        proxyBid.length === 0 && isLoading === false &&
                        <div className="px-3 py-2 font-sm text-center text-secondary w-100">Tidak ada Proxy Bid</div>
                    }
                </div>
                {
                    paginate.page !== paginate.total && <MoreButton onClick={loadMore} />
                }
            </div>
        </>
    )
}

export default ProxyBidContent;