//layout
import AuthLayout from "../containers/Layouts/AuthLayout/AuthLayout";
import MainLayout from "../containers/Layouts/MainLayout/MainLayout";
import LupaPassword from "../pages/Auth/LupaPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import VerifikasiAkun from "../pages/Auth/VerifikasiAkun";
import BeliNIPL from "../pages/BeliNIPL/BeliNIPL";
import DetailTransaksi from "../pages/DetailTransaksi/DetailTransaksi";
import LiveAuction from "../pages/LiveAuction/LiveAuction";
import Setting from "../pages/Setting/Setting";
import Checkout from "../pages/Checkout/Checkout.js";
import DetailTransaksiPenjualan from "../pages/DetailTransaksi/DetailTransaksiPenjualan";
// import LiveAuctionPublic from "../pages/LiveAuctionPublic/LiveAuctionPublic";
// import FullscreenLayout from "../containers/Layouts/FullscreenLayout.js/FullscreenLayout";

export const authList = [
    {
        path: '/masuk',
        component: SignIn,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/daftar',
        component: SignUp,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/verifikasi-akun',
        component: VerifikasiAkun,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/lupa-password',
        component: LupaPassword,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/reset-password/:token/:email',
        component: ResetPassword,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        layout: AuthLayout,
        isLogged: false,
    },
    {
        path: '/beli-nipl',
        component: BeliNIPL,
        layout: MainLayout,
        isLogged: true,
    },
    {
        path: '/checkout',
        component: Checkout,
        layout: MainLayout,
        isLogged: true,
    },
    {
        path: '/pengaturan/transaksi/pembelian/:id',
        component: DetailTransaksi,
        layout: MainLayout,
        isLogged: true,
    },
    {
        path: '/pengaturan/transaksi/penjualan/:id',
        component: DetailTransaksiPenjualan,
        layout: MainLayout,
        isLogged: true,
    },
    {
        path: '/pengaturan/:type',
        component: Setting,
        layout: MainLayout,
        isLogged: true,
    },
    // {
    //     path: '/live-auction/:id/public',
    //     component: LiveAuctionPublic,
    //     layout: FullscreenLayout,
    //     isLogged: false,
    // },
    {
        path: '/live-auction/:id',
        component: LiveAuction,
        layout: MainLayout,
        isLogged: true,
    },
]