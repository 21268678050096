import React from 'react'

const Service = ({
    title,
    desc,
    icon
}) => {
    return (
        <>
            <div className="services">
                <div className="mb-2">
                    <img src={icon} alt='' className='rounded-circle' />
                </div>
                <div className="title">{title}</div>
                <div className="desc">{desc}</div>
            </div>
        </>
    )
}

export default Service;
