import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ArrowBottom from '../../assets/icons/ArrowBottom';
import User from '../../assets/icons/User';
import withAuth from './../../services/withAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { actionsAuth, userSelector } from '../../redux/auth.slice';
import { useEffect, useState } from 'react';

const MyBadge = ({ text }) => (
  <span
    className='text-dark fw-normal px-2 ms-2 rounded'
    style={{ backgroundColor: '#F0F2F5' }}
  >
    {text}
  </span>
);

export const DropdownProfile = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [counter, setCounter] = useState([]);

  const handleLogout = async () => {
    const response = await withAuth.postDataWithAuth('/api/logout', {});
    if (response.status === 200) {
      dispatch(actionsAuth.logout({}));
      push('/');
    }
  };

  const getCounter = async () => {
    const response = await withAuth.getDataWithAuth(`/api/account/counter`, {});
    if (response?.status === 200) {
      const { data } = response.data;
      setCounter(data);
    } else {
      // console.log(response);
    }
  };

  const toggled = () => {
    getCounter();
  };

  return (
    <Dropdown align='end' onToggle={toggled}>
      <Dropdown.Toggle
        id='dropdown-basic'
        variant='light'
        className='disable-caret px-1 cursor-pointer hover-primary bg-header'
      >
        <div className='px-md-3 px-2 py-2 d-flex align-items-center text-secondary fw-bold'>
          <div
            className='d-inline-block rounded-circle me-2'
            style={{
              backgroundColor: '#E4E7EB',
              width: '40px',
              height: '40px',
            }}
          >
            <div className='d-flex justify-content-center h-100 align-items-center'>
              <User />
            </div>
          </div>
          <div className='me-2 hover-primary d-none d-md-block'>
            {user?.name}
          </div>
          <ArrowBottom />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: '200px' }}>
        <Dropdown.ItemText>
          <div
            className='dropdown-item-header'
            onClick={() => {
              push('/pengaturan/profile');
            }}
          >
            Pengaturan
          </div>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <div
            className='dropdown-item-header'
            onClick={() => {
              push('/pengaturan/transaksi');
            }}
          >
            Transaksi
          </div>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <div
            className='dropdown-item-header'
            onClick={() => {
              push('/pengaturan/favorit');
            }}
          >
            Favorit
          </div>
        </Dropdown.ItemText>
        <Dropdown.ItemText>
          <div
            className='dropdown-item-header d-flex justify-content-between'
            onClick={() => {
              push('/pengaturan/proxy-bid');
            }}
          >
            <div>Proxy Bid</div>
            <MyBadge text={counter?.proxy_bid || 0} />
          </div>
        </Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.ItemText>
          <div
            className='dropdown-item-header text-primary'
            onClick={() => handleLogout()}
          >
            Keluar
          </div>
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  );
};
