import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ path, layout: Layout, page: Page, isLogged }) => {
    useEffect(() => {
        scrollToTop();
    }, [path]);

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const userLoggedIn = useSelector((state) => state.authReducer.isLogged);
    if (isLogged) {
        if (userLoggedIn) {
            return (
                <Route path={path} >
                    <Layout>
                        <Page />
                    </Layout>
                </Route>
            )
        } else {
            return (
                <Redirect to="/masuk" />
            )
        }
    } else {
        if (userLoggedIn) {
            return (
                <Redirect to="/" />
            )
        } else {
            return (
                <Route path={path} >
                    <Layout>
                        <Page />
                    </Layout>
                </Route>
            )
        }
    }
    // if (userLoggedIn) {
    //     return (
    //         <Redirect to="/" />
    //     )
    // } else {
    //     return (
    //         <Route path={path} >
    //             <Layout>
    //                 <Page />
    //             </Layout>
    //         </Route>
    //     )
    // }

}

export default ProtectedRoute;