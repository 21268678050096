import { useState, useEffect } from 'react';
import ArrowRight from '../../../assets/icons/ArrowRight';
import ModalLayout from '../../../containers/Layouts/ModalLayout/ModalLayout';
import withAuthProvider from '../../../services/withAuthProvider';
import { dateFormatter } from '../../../utils/string';
import DetailTagihanNIPL from '../../Modal/PembayaranBeliNIPL/DetailTagihanNIPL';
import MoreButton from '../../MoreButton/MoreButton';
import { SkeletonNIPLTagihan } from '../../Skeleton/Skeleton';
import toast from 'react-hot-toast';

const initialPaginate = {
  page: 1,
  total: 1,
};

const Tagihan = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState([]);
  const [transaction, setTransaction] = useState();

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getDataList = async () => {
    const params = {
      page: paginate.page,
      page_size: 5,
      status: 'PENDING',
    };
    const response = await withAuthProvider.getDataWithAuth(
      `api/nipl/transaction`,
      params
    );
    if (response.status === 200) {
      const res = response.data.data;
      // console.log(res.data);
      setData((prev) => [...prev, ...res.data]);
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
      // console.log(response.response);
    }
  };

  useEffect(() => {
    getDataList().finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [paginate.page]);

  useEffect(() => {
    const detail = data.find((data) => data.id === parseInt(transaction));
    if (typeof detail !== 'undefined') {
      setIsOpen(true);
      setDetail(detail);
    }
  }, [transaction]);

  const closeModal = () => {
    setIsOpen(false);
    setTransaction();
  };

  return (
    <>
      <div className='overflow-auto' style={{ maxHeight: '600px' }}>
        <div className='d-flex flex-column' style={{ gap: '10px' }}>
          {isLoading
            ? new Array(2).fill(1).map((a, idx) => (
                <div className='border rounded-custom p-3 font-sm' key={idx}>
                  <SkeletonNIPLTagihan />
                </div>
              ))
            : data.map((el, idx) => (
                <div className='border rounded-custom p-3 font-sm' key={idx}>
                  <div className='fw-bold mb-1'>
                    {el?.unit_type_name} - {el?.qty} NIPL
                  </div>
                  <div className='row tagihan-gap'>
                    <div className='col-md-4'>
                      <div className='text-secondary'>
                        Total tagihan:
                        <span className='ms-1 text-dark fw-semibold'>
                          Rp. {el?.grand_total.toLocaleString()}
                        </span>
                      </div>
                      <div className='text-secondary'>
                        Metode Pembayaran:
                        <span className='ms-1 text-dark fw-semibold'>
                          {el?.payment_method || '-'}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='text-secondary'>
                        No. VA:
                        <span className='ms-1 text-dark fw-semibold'>
                          {el?.payment_bank_virtual_account || '-'}
                        </span>
                      </div>
                      <div className='text-secondary'>
                        Bayar Sebelum:
                        <span className='ms-1 text-dark fw-semibold'>
                          {dateFormatter(el?.expiry_date)}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div
                        className='d-flex align-items-end justify-content-start justify-content-md-end cursor-pointer text-primary h-100 fw-semibold'
                        onClick={() => setTransaction(el?.id)}
                      >
                        <div className='d-flex align-items-center'>
                          Lihat Detail Tagihan
                          <ArrowRight className='ms-2' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          {data.length === 0 && isLoading === false && (
            <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
              Tidak ada Tagihan
            </div>
          )}
        </div>
        {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
      </div>
      <ModalLayout
        show={isOpen}
        handleClose={closeModal}
        title='Menunggu Pembayaran'
        className=''
      >
        <DetailTagihanNIPL invoice={detail} />
      </ModalLayout>
    </>
  );
};

export default Tagihan;
