import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, FormControl, Button, Alert } from "react-bootstrap";
import withoutAuth from "../../../services/withoutAuthProvider";
import withAuth from "../../../services/withAuthProvider";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { actionsAuth } from "../../../redux/auth.slice";
import { tokenSelector } from "../../../redux/auth.slice";
import iconImage from "../../../assets/images/upload-image.png";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from "axios";
import Jimp from "jimp";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const months = [
    {
        id: "01",
        name: "Jan",
    },
    {
        id: "02",
        name: "Feb",
    },
    {
        id: "03",
        name: "Mar",
    },
    {
        id: "04",
        name: "Apr",
    },
    {
        id: "05",
        name: "May",
    },
    {
        id: "06",
        name: "Jun",
    },
    {
        id: "07",
        name: "Jul",
    },
    {
        id: "08",
        name: "Aug",
    },
    {
        id: "09",
        name: "Sep",
    },
    {
        id: "10",
        name: "Oct",
    },
    {
        id: "11",
        name: "Nov",
    },
    {
        id: "12",
        name: "Dec",
    },
];

export default function ProfilContent() {
    const [showAlert, setShowAlert] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState({
        day: "",
        month: "",
        year: "",
    });
    const dispatch = useDispatch();
    const token = useSelector(tokenSelector);
    const [buffer, setBuffer] = useState("");

    const uploadImageRef = useRef();
    const fileUploadAction = () => uploadImageRef.current.click();

    const [dataKTP, setDataKTP] = useState({
        image_file: null,
        image_preview: "",
    });

    const handleChange = (name, value) => {
        setData((data) => {
            return {
                ...data,
                [name]: value,
            };
        });
    };
    const handleChangeDateOfBirth = (name, value) => {
        setDateOfBirth((data) => {
            return {
                ...data,
                [name]: value,
            };
        });
    };

    const handleChangeSameAsAddress = () => {
        if (!isSame) {
            // setIsSame(true)
            if (data.current_address !== data.personal_id_address) {
                setData(last_data => ({
                    ...last_data,
                    current_address: data.personal_id_address,
                }));
            }
        }
    };

    function replaceAll(str, mapObj) {
        var re = new RegExp(Object.keys(mapObj).join("|"), "gi");

        return str.replace(re, function (matched) {
            return mapObj[matched.toLowerCase()];
        });
    }

    const fileUploadInputChange = (e) => {
        var file = e.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);

            setDataKTP({ image_file: file, image_preview: objectUrl });

            Jimp.read(objectUrl)
                .then((lenna) => {
                    lenna
                        .greyscale()
                        .invert()
                        .getBase64(Jimp.MIME_JPEG, function (err, buffer) {
                            setBuffer(buffer);
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    useEffect(async () => {
        if (buffer) {
            const dataImage = {
                requests: [
                    {
                        image: {
                            content: buffer.slice(23),
                        },
                        features: [
                            {
                                type: "DOCUMENT_TEXT_DETECTION",
                            },
                        ],
                    },
                ],
            };

            await axios
                .post(
                    "https://vision.googleapis.com/v1/images:annotate?key=" +
                    process.env.REACT_APP_GOOGLE_API,
                    dataImage,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((r) => {
                    const datas = JSON.stringify({
                        event_type: "text.recognized",
                        data: {
                            results: r.data.responses[0].textAnnotations,
                        },
                    });

                    const text = JSON.parse(datas);
                    const text_val = text["data"]["results"][0]["description"];
                    const lines = text_val.toString().split("\n");
                    var objMap = {
                        nik: "",
                        nama: "",
                        "tempat/tgl lahir": "",
                        NIK: "",
                        alamat: "",
                        "jenis kelamin": "",
                        "gol. darah": "",
                        "gol darah": "",
                        "rt/rw": "",
                        kel: "",
                        desa: "",
                        kecamatan: "",
                        "status perkawinan": "",
                        pekerjaan: "",
                        kewarganegaraan: "",
                        "berlaku hingga": "",
                        ":": "",
                        "/": " ",
                    };

                    for (var i = 0; i < lines.length; i++) {
                        lines[i] = replaceAll(lines[i], objMap);
                        lines[i] = lines[i].trim();
                        lines[i] = lines[i].replace(/\./g, "");
                    }
                    const newArr = lines.filter((a) => a);

                    var NoKTP = "";
                    var Alamat = "";
                    var tanggal_lahir = "";
                    var RT = "";
                    var RW = "";

                    if (newArr[2].length === 16 && isNaN(newArr[2]) === false) {
                        NoKTP = newArr[2];
                    }

                    if (newArr[4].includes(",")) {
                        var ttl = newArr[4].split(",");
                        if (Array.isArray(ttl) && ttl.length) {
                            tanggal_lahir = ttl[1];
                        }
                    }

                    var index = 6;
                    if (isNaN(newArr[7].replace(/ /g, "")) === false) {
                        Alamat = newArr[index];
                    } else {
                        Alamat = newArr[index] + " " + newArr[index + 1];
                        index++;
                    }
                    index++;

                    var rtrw = newArr[index].replace(/\s/g, "").split("");
                    if (rtrw.length === 6) {
                        RT = rtrw[0] + rtrw[1] + rtrw[2];
                        RW = rtrw[3] + rtrw[4] + rtrw[5];
                    }
                    index++;

                    var arrData = [
                        NoKTP ? NoKTP.trim() : "",
                        newArr[3] ? newArr[3].trim() : "",
                        tanggal_lahir ? tanggal_lahir.trim() : "",
                        Alamat ? Alamat.trim() : "",
                        RT ? RT.replace(/\D/g, "").trim() : "",
                        RW ? RW.replace(/\D/g, "").trim() : "",
                        newArr[index]
                            ? newArr[index].replace(/[^a-zA-Z0-9 ]/g, "").trim()
                            : "",
                        newArr[index + 1]
                            ? newArr[index + 1]
                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                .trim()
                            : "",
                        newArr[1]
                            ? newArr[1].replace(/[^a-zA-Z0-9 ]/g, "").trim()
                            : "",
                        newArr[index + 4]
                            ? newArr[index + 4]
                                .replace(/[^A-Za-z ]+/g, "")
                                .trim()
                            : "",
                        newArr[index + 5]
                            ? newArr[index + 5]
                                .replace(/[^A-Za-z ]+/g, "")
                                .trim()
                            : "",
                    ];

                    var countNotNull = arrData.filter(String).length;
                    var persentageNotNull =
                        (countNotNull / arrData.length) * 100;

                    if (persentageNotNull > 50) {
                        setData({
                            ...data,
                            personal_id_number: arrData[0],
                            personal_id_address: `${arrData[3]} ${arrData[4]} ${arrData[5]} ${arrData[6]} ${arrData[7]}`,
                        });
                        let newDate = arrData[2].split("-");
                        setDateOfBirth({
                            day: newDate[0],
                            month: newDate[1],
                            year: newDate[2],
                        });
                        setValue('personal_id_number', arrData[0]);
                        setValue('personal_id_address', `${arrData[3]} ${arrData[4]} ${arrData[5]} ${arrData[6]} ${arrData[7]}`);
                        setValue('current_address', `${arrData[3]} ${arrData[4]} ${arrData[5]} ${arrData[6]} ${arrData[7]}`);
                    } else {

                        setData({
                            ...data,
                            personal_id_number: "",
                            personal_id_address: "",
                        });
                        setDateOfBirth({
                            day: "",
                            month: "",
                            year: "",
                        });

                        setValue('personal_id_number', '');
                        setValue('personal_id_address', '');
                        setValue('address', '');
                        toast.error("Foto e-KTP buram / tidak jelas. Silahkan ulangi kembali.", {
                            id: "error-message",
                            duration: 3000,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [buffer]);

    const getCountries = async () => {
        const response = await withoutAuth.getDataWithoutAuth(
            "/api/country",
            {}
        );
        if (response.status === 200) {
            setCountry(response.data.data.data);
        } else {
            toast.error(response.data.message, {
                id: "error-message",
                duration: 3000,
            });
        }
    };

    const getCities = async () => {
        const response = await withoutAuth.getDataWithoutAuth(
            "/api/city",
            {}
        );
        if (response.status === 200) {
            setCityData(response.data.data.data);
        } else {
            toast.error(response.data.message, {
                id: "error-message",
                duration: 3000,
            });
        }
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required("Silahkan mengisi nama lengkap"),
        email: Yup.string().required("Silahkan mengisi e-mail").email("E-mail tidak valid"),
        phone_number: Yup.string().required("Silahkan mengisi nomor telepon"),
        personal_id_number: Yup.string().required("Silahkan mengisi nomor KTP"),
        personal_id_address: Yup.string().required("Silahkan mengisi alamat sesuai identitas"),
        current_address: Yup.string().required("Silahkan mengisi alamat saat ini"),
    });

    // functions to build form returned by useForm() hook
    const { register, watch, control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const getProfile = async () => {
        const response = await withAuth.getDataWithAuth(
            "/api/account/profile",
            {}
        );
        if (response?.status === 200) {
            const dataProfile = response.data.data;
            reset(dataProfile)
            setData(dataProfile);
            setCity(
                dataProfile.city
                    ? {
                        label: dataProfile?.city?.name,
                        value: dataProfile?.city?.id,
                    }
                    : {}
            );
          setValue('city_id', dataProfile?.city?.id)
          if (dataProfile?.city) {
            setSelectedCity([dataProfile?.city])
          }

            if (dataProfile?.full_name == null || dataProfile?.country_id === null || dataProfile?.city_id === null || dataProfile?.npwp_number === null || dataProfile?.personal_id_file_url == null || dataProfile?.personal_id_number == null || dataProfile?.personal_id_address == null || dataProfile?.current_address == null || dataProfile?.date_of_birth == null || dataProfile?.email == null || dataProfile?.phone_number == null) {
                setShowAlert(true)
            } else {
                setShowAlert(false)
            }

            const birthday = dataProfile.date_of_birth ?? "--";
            const splitDateBirthday = birthday.split("-");
            const date_birthday = {
                year: splitDateBirthday[0],
                month: splitDateBirthday[1],
                day: splitDateBirthday[2],
            };
            setDateOfBirth(date_birthday);

            if (dataProfile.current_address === dataProfile.personal_id_address) {
                setValue('isSame', true)
            } else {
                setValue('isSame', false)
            }

        } else {
            toast.error(response?.data.message, {
                id: "error-message",
                duration: 3000,
            });
        }
    };

    const isSame = watch("isSame", false); // you can supply default value as second argument


    const onSubmit = async (data, e) => {
        e.preventDefault();
        const dataProfile = new FormData();

        dataProfile.append("name", data.full_name);
        dataProfile.append("country_id", data.country_id);
        // dataProfile.append("personal_id_type", data.personal_id_type);
        dataProfile.append("personal_id_type", "KTP")
        dataProfile.append("personal_id_image", dataKTP.image_file);
        dataProfile.append("personal_id_number", data.personal_id_number);
        // dataProfile.append("personal_id_expired_at", expiredID.year + "-" + expiredID.month + "-" + expiredID.day);
        dataProfile.append("personal_id_address", data.personal_id_address);
        dataProfile.append("address", data.current_address);
        dataProfile.append("email", data.email);
        dataProfile.append("phone", data.phone_number);
        dataProfile.append(
            "date_of_birth",
            dateOfBirth.year + "-" + dateOfBirth.month + "-" + dateOfBirth.day
        );
        dataProfile.append("npwp_number", data.npwp_number);
        // dataProfile.append("city_id", data.city_id);

        dataProfile.append("city_id", selectedCity[0]?.id);

        const loading = toast.loading("Mengirimkan...");
        const response = await withAuth.postDataWithAuth(
            "/api/account/profile/update",
            dataProfile
        );
        if (response.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: "success-message",
                duration: 3000,
            });
            const responseUserData = await withAuth.getDataWithAuth(
                "/api/account/user",
                {}
            );
            if (responseUserData.status === 200) {
                dispatch(
                    actionsAuth.setUser({
                        token: token,
                        user: responseUserData.data.data,
                    })
                );
            }
        } else {
            toast.dismiss(loading);
            if (
                typeof response.data.message === "object" &&
                response.data.message !== null &&
                !Array.isArray(response.data.message)
            ) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key];
                    }
                }
                toast.error(data_message, {
                    id: "error-message",
                    duration: 3000,
                });
            } else {
                toast.error(response.data.message, {
                    id: "error-message",
                    duration: 3000,
                });
            }
        }
        // e.target.reset();
    };

    useEffect(() => {
        getCountries();
        getProfile();
        getCities();
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name == "isSame" && type == "change") {
                if (value.current_address !== value.personal_id_address) {
                    reset({ current_address: value.personal_id_address })
                }
            }
            if ((name == "current_address" && type == "change") || (name == "personal_id_address" && type == "change")) {
                if (value.current_address !== value.personal_id_address) {
                    reset({ isSame: false })
                }
                if (value.current_address === value.personal_id_address) {
                    reset({ isSame: true })
                }
            }
            console.log(value, name, type)
        });

        return () => subscription.unsubscribe();
    }, [watch])


    const loadOptions = (inputValue, callback) => {
        setTimeout(async () => {
            var cities = [];
            //if (inputValue.length >= 3) {
            const response = await withoutAuth.getDataWithoutAuth(
                "/api/city",
                {
                    search: inputValue,
                }
            );
            if (response.status === 200) {
                var data_cities = response.data.data.data;
                var cities = data_cities.map((row) => ({
                    label: row.name,
                    value: row.id,
                }));
            }
            //}
            callback(cities);
        }, 1000);
    };

    const handleOnChange = (selectedCity) => {
        setCity(selectedCity);
        handleChange("city_id", selectedCity.value);
    };

    return (
        <>
            {showAlert ? (
                <Alert key={"alert"} variant={'warning'}>
                    <div className="group">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00059 20V14C4.00059 11.8783 4.84345 9.84344 6.34374 8.34315C7.84403 6.84285 9.87886 6 12.0006 6C14.1223 6 16.1572 6.84285 17.6574 8.34315C19.1577 9.84344 20.0006 11.8783 20.0006 14V20H21.0006V22H3.00059V20H4.00059ZM6.00059 14H8.00059C8.00059 12.9391 8.42202 11.9217 9.17217 11.1716C9.92231 10.4214 10.9397 10 12.0006 10V8C10.4093 8 8.88317 8.63214 7.75795 9.75736C6.63273 10.8826 6.00059 12.4087 6.00059 14ZM11.0006 2H13.0006V5H11.0006V2ZM19.7786 4.808L21.1926 6.222L19.0726 8.343L17.6576 6.929L19.7786 4.808ZM2.80859 6.222L4.22259 4.808L6.34359 6.928L4.93059 8.344L2.80859 6.222Z" fill="#EA9437" />
                        </svg>
                        <div> Lengkapi profil Anda untuk dapat melakukan <strong>Lelang</strong></div>
                    </div>
                </Alert>
            ) : ""
            }
            <h4 className="sub-title">Informasi Akun</h4>
            <Row>
                <Col md={6}>
                    <Form className="form-caready" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="form-group">
                            <Form.Label className="required">
                                Nama Lengkap
                            </Form.Label>
                            <FormControl
                                placeholder="Nama Lengkap"
                                aria-label="Nama Lengkap"
                                aria-describedby="basic-addon1"
                                {...register('full_name')}
                            />
                            {errors.full_name?.message && <span className='error-message'>{errors.full_name?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label className="required">
                                Kewarganegaraan
                            </Form.Label>
                            <Form.Select
                                placeholder="Kewarganegaraan"
                                {...register('country_id')}
                            >
                                {/* <option value="">Kewarganegaraan</option> */}
                                {countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                        {country.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.country_id?.message && <span className='error-message'>{errors.country_id?.message}</span>}
                        </Form.Group>
                        {/* <Form.Group className="form-group">
                            <Form.Label className="required">Kota</Form.Label>
                            <Form.Select
                                placeholder="Kota"
                                {...register('city_id')}
                            >
                                <option value='all'>Pilih kota</option>
                                {cityData.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.city_id?.message && <span className='error-message'>{errors.city_id?.message}</span>}
                        </Form.Group> */}
                        {/* ini yang baru */}
                        <Form.Group className="form-group">
                            <Form.Label className="required">Kota</Form.Label>
                            <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                onChange={setSelectedCity}
                                options={cityData}
                                placeholder="Kota"
                                selected={selectedCity}
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>NPWP</Form.Label>
                            <FormControl
                                placeholder="NPWP"
                                aria-label="NPWP"
                                aria-describedby="basic-addon1"
                                {...register('npwp_number')}
                            />
                            {errors.npwp_number?.message && <span className='error-message'>{errors.npwp_number?.message}</span>}
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label className="required">KTP</Form.Label>
                            <div className="d-flex flex-column flex-md-row">
                                <div>
                                    <div
                                        className="image-preview center-horizontal-vertical"
                                        style={
                                            dataKTP.complete ||
                                                data.personal_id_file_url
                                                ? {}
                                                : {
                                                    background: `url(${iconImage})`,
                                                }
                                        }
                                    >
                                        {dataKTP.image_preview && (
                                            <img
                                                src={dataKTP.image_preview}
                                                alt="preview"
                                            />
                                        )}
                                        {data.personal_id_file_url &&
                                            !dataKTP.image_preview && (
                                                <img
                                                    src={
                                                        data.personal_id_file_url
                                                    }
                                                    alt="preview"
                                                />
                                            )}
                                    </div>
                                </div>
                                <div className="center-horizontal-vertical">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={uploadImageRef}
                                        style={{ display: "none" }}
                                        id="contained-button-file"
                                        onChange={fileUploadInputChange}
                                    // {...register('upload_ktp')}
                                    />
                                    {/* <Button type='button' className='btn-upload' onClick={handleClick}> */}
                                    <Button
                                        type="button"
                                        className="btn-upload button-responsive"
                                        onClick={fileUploadAction}
                                    >
                                        Upload e-KTP
                                    </Button>
                                </div>
                            </div>
                            {/* {errors.upload_ktp?.message && <span className='error-message'>{errors.upload_ktp?.message}</span>} */}
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label className="required">
                                No. KTP
                            </Form.Label>
                            <FormControl
                                placeholder="No. KTP"
                                aria-label="No. KTP"
                                aria-describedby="basic-addon1"
                                {...register('personal_id_number')}

                            />
                            {errors.personal_id_number?.message && <span className='error-message'>{errors.personal_id_number?.message}</span>}
                        </Form.Group>

                        {/* <Form.Group className="form-group">
                            <Form.Label>Tanggal Kadaluarsa</Form.Label>
                            <div className='group'>
                                <Form.Select
                                    placeholder="Pilih Tanggal"
                                    value={expiredID.day}
                                    onChange={(e) => handleChangeExpiredID("day", e.target.value)}
                                >
                                    <option value="">Pilih Tanggal</option>
                                    {Array(31)
                                        .fill(null)
                                        .map((_, index) => {
                                            var val = 1 + index;
                                            if (val.toString().length === 1) {
                                                val = "0" + val;
                                            }
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                                <Form.Select
                                    placeholder="Pilih Bulan"
                                    value={expiredID.month}
                                    onChange={(e) => handleChangeExpiredID("month", e.target.value)}
                                >
                                    <option value="">Pilih Bulan</option>
                                    {months.map((month) => (
                                        <option key={month.id} value={month.id}>
                                            {month.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Select
                                    placeholder="Pilih Tahun"
                                    value={expiredID.year}
                                    onChange={(e) => handleChangeExpiredID("year", e.target.value)}
                                >
                                    <option value="">Pilih Tahun</option>
                                    {Array(20)
                                        .fill(null)
                                        .map((_, index) => {
                                            return (
                                                <option key={2010 + index} value={2010 + index}>
                                                    {2010 + index}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>
                        </Form.Group> */}

                        <Form.Group className="form-group">
                            <Form.Label className="required">Alamat KTP/Passport</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                {...register('personal_id_address')}
                            />
                            {errors.personal_id_address?.message && <span className='error-message'>{errors.personal_id_address?.message}</span>}
                        </Form.Group>
                        <Form.Group className="form-group">
                            <div className="d-flex flex-column flex-md-row">
                                <Form.Label className="required me-2">Alamat Saat Ini </Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="Sama Dengan KTP/Passport"
                                    style={{ flex: "1" }}
                                    {...register("isSame")}
                                />
                            </div>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                {...register('current_address')}
                            />
                            {errors.current_address?.message && <span className='error-message'>{errors.current_address?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Tanggal Lahir</Form.Label>
                            <div className="group">
                                <Form.Select
                                    placeholder="Pilih Tanggal"
                                    value={dateOfBirth.day}
                                    onChange={(e) =>
                                        handleChangeDateOfBirth(
                                            "day",
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="">Pilih Tanggal</option>
                                    {Array(31)
                                        .fill(null)
                                        .map((_, index) => {
                                            var val = 1 + index;
                                            if (val.toString().length === 1) {
                                                val = "0" + val;
                                            }
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                                <Form.Select
                                    placeholder="Pilih Bulan"
                                    value={dateOfBirth.month}
                                    onChange={(e) =>
                                        handleChangeDateOfBirth(
                                            "month",
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="">Pilih Bulan</option>
                                    {months.map((month) => (
                                        <option key={month.id} value={month.id}>
                                            {month.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Select
                                    placeholder="Pilih Tahun"
                                    value={dateOfBirth.year}
                                    onChange={(e) =>
                                        handleChangeDateOfBirth(
                                            "year",
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="">Pilih Tahun</option>
                                    {Array(70)
                                        .fill(null)
                                        .map((_, index) => {
                                            return (
                                                <option
                                                    key={2010 - index}
                                                    value={2010 - index}
                                                >
                                                    {2010 - index}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label className="required">E-mail Akun</Form.Label>
                            <FormControl
                                placeholder="E-mail Akun"
                                aria-label="E-mail Akun"
                                aria-describedby="basic-addon1"
                                {...register('email')}
                            />
                            {errors.email?.message && <span className='error-message'>{errors.email?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label className="required">Nomor Telepon</Form.Label>
                            <FormControl
                                placeholder="Nomor Telepon"
                                aria-label="Nomor Telepon"
                                aria-describedby="basic-addon1"
                                {...register('phone_number')}
                            />
                            {errors.phone_number?.message && <span className='error-message'>{errors.phone_number?.message}</span>}
                        </Form.Group>

                        <Button
                            className="button-login mb-3"
                            style={{
                                display: "block !important",
                                width: "100%",
                            }}
                            variant="primary"
                            type="submit"
                        >
                            Simpan
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
}
