import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import withAuth from '../../../services/withAuthProvider';
import toast from 'react-hot-toast';
import { PasswordField } from '../../PasswordField/PasswordField';

const initial = {
  old_password: '',
  new_password: '',
  new_password_confirmation: '',
};
export default function UbahKataSandi() {
  const [data, setData] = useState({ ...initial });
  const [error, setError] = useState({ ...initial });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let err = '';

    if (name === 'new_password_confirmation') {
      if (value.length <= 6) {
        err = `Password harus lebih dari 6`;
      } else if (value !== data.new_password) {
        err = `Password harus sama dengan kata sandi baru`;
      } else if (checkSpecialCharacter(value)) {
        err = `Password tidak boleh menggunakan karakter spesial`;
      } else {
        err = '';
      }
    } else {
      if (value.length <= 6) {
        err = `Password harus lebih dari 6`;
      } else if (checkSpecialCharacter(value)) {
        err = `Password tidak boleh menggunakan karakter spesial`;
      } else {
        err = '';
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError((prev) => ({
      ...prev,
      [name]: err,
    }));
  };

  const checkSpecialCharacter = (value) => {
    let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const dataPassword = new FormData();

    dataPassword.append('old_password', data.old_password);
    dataPassword.append('password', data.new_password);
    dataPassword.append(
      'password_confirmation',
      data.new_password_confirmation
    );

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (checkSpecialCharacter(data[key])) {
          toast.error('Password tidak boleh menggunakan karakter spesial', {
            id: 'error-message',
            duration: 3000,
          });
          return;
        }
      }
    }

    const loading = toast.loading('Mengirimkan...');
    const response = await withAuth.postDataWithAuth(
      '/api/account/change-password',
      dataPassword
    );
    if (response.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });
    } else {
      toast.dismiss(loading);
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <h4 className='sub-title'>Ubah Kata Sandi</h4>
      <Row>
        <Col md={6}>
          <Form className='form-caready' onSubmit={onSubmit}>
            <div className='mb-3'>
              <PasswordField
                id='old_password'
                name='old_password'
                placeholder='Masukan Password Anda'
                onChange={handleChange}
                label='Password Lama'
                value={data.password}
              />
              {error.old_password && (
                <span className='error-message'>{error.old_password}</span>
              )}
            </div>
            <div className='mb-3'>
              <PasswordField
                id='new_password'
                name='new_password'
                placeholder='Masukan Password Baru Anda'
                onChange={handleChange}
                label='Password Baru'
                value={data.new_password}
              />
              {error.new_password && (
                <span className='error-message'>{error.new_password}</span>
              )}
            </div>
            <div className='mb-3'>
              <PasswordField
                id='new_password_confirmation'
                name='new_password_confirmation'
                placeholder='Masukan Password Konfirmasi'
                onChange={handleChange}
                label='Konfirmasi Password Baru'
                value={data.new_Password_confirmation}
              />
              {error.new_password_confirmation && (
                <span className='error-message'>
                  {error.new_password_confirmation}
                </span>
              )}
            </div>
            <Button
              className='button-login mb-3'
              style={{ display: 'block !important', width: '100%' }}
              variant='primary'
              type='submit'
            >
              Simpan Kata Sandi Baru
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
