import { useEffect, useState } from 'react';
import { getDefaultLocale } from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import Facebook from '../../assets/icons/Facebook';
import Google from '../../assets/icons/Google';
import InputField from '../../components/InputField/InpurField';
import { PasswordField } from '../../components/PasswordField/PasswordField';
import withoutAuth from '../../services/withoutAuthProvider';
import withAuth from '../../services/withAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { actionsAuth } from '../../redux/auth.slice';
import { setToken } from '../../services/withAuthProvider';
import { Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import decodeJwtResponse from 'jwt-decode';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const initialData = {
  email: '',
  password: '',
  remember_me: false,
};

const SignIn = () => {
  // ini adalah query params
  const { search } = useLocation();
  const queryP = new URLSearchParams(search);
  const verifyMsg = queryP.get('verifyMsg');
  const type = queryP.get('type');

  const [data, setData] = useState({ ...initialData });
  const [next, setNext] = useState(false);
  const [error, setError] = useState(null);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const google = window?.google;
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const handleClick = (path) => {
    push(path);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const dataLogin = {
      email: data.email,
      password: data.password,
    };

    const response = await withoutAuth.postDataWithoutAuth(
      '/api/login',
      dataLogin
    );
    if (response)
      if (response.status === 200) {
        //Set token to withAuthProvider
        withAuth.setToken(response.data.data.accessToken);

        const responseUserData = await withAuth.getDataWithAuth(
          '/api/account/user',
          {}
        );
        if (responseUserData.status === 200) {
          if (data.remember_me)
            localStorage.setItem('remember_me', JSON.stringify(data.email));
          else localStorage.removeItem('remember_me');

          dispatch(
            actionsAuth.setUser({
              token: response.data.data.accessToken,
              user: responseUserData.data.data,
            })
          );
        }
      } else {
        if (
          typeof response.data.message === 'object' &&
          response.data.message !== null &&
          !Array.isArray(response.data.message)
        ) {
          var data_message = '';
          for (var key in response.data.message) {
            if (response.data.message.hasOwnProperty(key)) {
              data_message += response.data.message[key];
            }
          }
          toast.error(data_message, {
            id: 'error-message',
            duration: 3000,
          });
        } else {
          if (
            response.status === 400 &&
            response.data.message === 'Email belum terverifikasi'
          ) {
            push('/verifikasi-akun', {
              dataParams: {
                email: data.email,
              },
            });
          } else {
            toast.error(response.data.message, {
              id: 'error-message',
              duration: 3000,
            });
          }
        }
      }
    else
      toast.error('Gagal koneksi ke server', {
        id: 'error-message',
        duration: 3000,
      });
  };

  const responseFacebook = async (response) => {
    const dataUser = {
      id: response.id,
      email: response.email,
    };

    setLoadingFacebook(true);
    const loading = toast.loading('Mengirimkan...');
    const responseAPI = await withoutAuth.postDataWithoutAuth(
      '/api/login/social/facebook',
      dataUser
    );
    if (responseAPI.status === 200) {
      toast.dismiss(loading);
      toast.success(responseAPI.data.message, {
        id: 'success-message',
        duration: 3000,
      });
      setLoadingFacebook(false);
      //Set token to withAuthProvider
      withAuth.setToken(responseAPI.data.data.accessToken);

      const responseUserData = await withAuth.getDataWithAuth(
        '/api/account/user',
        {}
      );
      if (responseUserData.status === 200) {
        dispatch(
          actionsAuth.setUser({
            token: responseAPI.data.data.accessToken,
            user: responseUserData.data.data,
          })
        );
      }
    } else {
      toast.dismiss(loading);
      toast.error(responseAPI.data.message, {
        id: 'error-message',
        duration: 3000,
      });
      setLoadingFacebook(false);
    }
  };

  const responseGoogle = async (response) => {
    const responsePayload = decodeJwtResponse(response.credential);

    const dataUser = {
      id: responsePayload.sub,
      email: responsePayload.email,
    };

    setLoadingGoogle(true);
    const loading = toast.loading('Mengirimkan...');
    const responseAPI = await withoutAuth.postDataWithoutAuth(
      '/api/login/social/google',
      dataUser
    );
    if (responseAPI.status === 200) {
      toast.dismiss(loading);
      toast.success(responseAPI.data.message, {
        id: 'success-message',
        duration: 3000,
      });
      setLoadingGoogle(false);
      //Set token to withAuthProvider
      withAuth.setToken(responseAPI.data.data.accessToken);

      const responseUserData = await withAuth.getDataWithAuth(
        '/api/account/user',
        {}
      );
      if (responseUserData.status === 200) {
        dispatch(
          actionsAuth.setUser({
            token: responseAPI.data.data.accessToken,
            user: responseUserData.data.data,
          })
        );
      }
    } else {
      toast.dismiss(loading);
      toast.error(responseAPI.data.message, {
        id: 'error-message',
        duration: 3000,
      });
      setLoadingGoogle(false);
    }
    google?.accounts?.id?.disableAutoSelect();
  };

  useEffect(() => {
    const emailLocal = JSON.parse(localStorage.getItem('remember_me'));
    if (emailLocal) {
      setData({ ...data, email: emailLocal, remember_me: true });

      setNext(true);
    }
  }, []);

  useEffect(() => {
    if (type !== null) {
      //   console.log('tidak null');
      if (type === 'success') {
        let test = setInterval(() => {
          toast.success(verifyMsg.toString(), {
            id: 'success-message',
            duration: 1000,
          });
        }, 200);
        return () => {
          clearInterval(test);
          toast.dismiss();
        };
      } else {
        let test2 = setInterval(() => {
          toast.error(verifyMsg.toString(), {
            id: 'error-message',
            duration: 3000,
          });
        }, 200);
        return () => {
          clearInterval(test2);
          toast.dismiss();
        };
      }
    }
  }, [type]);

  useEffect(() => {
    google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: responseGoogle,
    });
    google?.accounts?.id?.renderButton(
      document.getElementById('buttonGoogle'),
      { theme: 'outline', size: 'medium', width: '195px' } // customization attributes
    );
  }, []);

  return (
    <div className='w-100'>
      <p className='fw-bold fs-4 mb-2'>Masuk</p>
      <div className='font-sm d-flex mb-3'>
        <div className='me-2 text-secondary'>Belum punya akun?</div>
        <div
          className='text-primary cursor-pointer'
          onClick={() => handleClick('/daftar')}
        >
          Daftar
        </div>
      </div>
      {error && <div className='alert alert-danger'>{error}</div>}
      <div>
        <div className='mb-3'>
          {next ? (
            <div className='mb-2 font-sm'>
              <label>Email</label>
              <div className='d-flex fw-bold justify-content-between'>
                <p className='mb-0'>{data.email}</p>
                <p
                  className='mb-0 text-primary cursor-pointer'
                  onClick={() => setNext(false)}
                >
                  Ubah
                </p>
              </div>
            </div>
          ) : (
            <InputField
              id='email'
              name='email'
              placeholder='Masukan Email Anda'
              onChange={handleChange}
              label='Email'
              type='email'
              value={data.email}
            />
          )}
          {next ? (
            <PasswordField
              id='password'
              name='password'
              placeholder='Masukan Password Anda'
              onChange={handleChange}
              label='Password'
              value={data.password}
            />
          ) : (
            ''
          )}
          <div className='d-flex mt-3 font-sm justify-content-between'>
            <div className='form-check'>
              {next ? (
                <>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='disabledFieldsetCheck'
                    name='remember_me'
                    onChange={(e) =>
                      setData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                    checked={data.remember_me}
                  />
                  <label className='ms-2' htmlFor='disabledFieldsetCheck'>
                    Ingat Saya
                  </label>
                </>
              ) : (
                ''
              )}
            </div>
            <p
              className='mb-0 fw-bold text-primary cursor-pointer'
              onClick={() => push('/lupa-password')}
            >
              Lupa Kata Sandi?
            </p>
          </div>
        </div>
        {next ? (
          <div className='d-grid gap-2'>
            <button
              className='btn btn-primary font-sm'
              type='button'
              onClick={handleSubmit}
            >
              Masuk
            </button>
          </div>
        ) : (
          <div className='d-grid gap-2'>
            <button
              className='btn btn-primary font-sm'
              onClick={() => setNext(true)}
            >
              Selanjutnya
            </button>
          </div>
        )}
        <div className='text-center'>
          <div className='font-sm text-secondary my-3 has-line'>
            atau masuk dengan
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='d-grid gap-2 mb-2 login-google'>
                <div id='buttonGoogle'></div>
                {/* <button type="button" className="btn d-flex align-items-center justify-content-center btn-block btn-outline-secondary text-dark font-sm" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <Google className="me-2" />
                                    Google
                                </button> */}
              </div>
            </div>
            {/* <div className='col-md-6'>
                            <div className="d-grid gap-2 d-flex mb-2 login-facebook" style={{ justifyContent: "flex-end" }}>
                                <FacebookLogin
                                    appId="1172350439997325"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    render={renderProps => (
                                        <button className="btn d-flex align-items-center justify-content-center btn-block btn-outline-secondary font-sm custom-facebook" style={{ background: '#FFF', fontWeight: 600, color: "#3c4043", height: "32px", border: "1px solid #dadce0", }} type="button" onClick={renderProps.onClick} disabled={loadingFacebook}>
                                            {loadingFacebook ? (<Spinner animation="border" size="sm" />) : ""}
                                            <Facebook className="me-2 logo-facebook" />
                                            Facebook
                                        </button>
                                    )}
                                />
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
