import { useState } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../../components/InputField/InpurField";
import withoutAuth from "../../services/withoutAuthProvider";
import toast from 'react-hot-toast';

const Arrow = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 15L7.5 10L12.5 5" stroke="#CF1701" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const LupaPassword = () => {
    const { push } = useHistory();
    const [email, setEmail] = useState('')
    const [error, setError] = useState([])
    const [message, setMessage] = useState(null)


    const handleChange = (e) => {
        const { value } = e.target;
        setEmail(value);
    }

    const handleSubmit = async () => {

        const loading = toast.loading('Mengirimkan...');

        const response = await withoutAuth.postDataWithoutAuth('/api/password/send', { email })
        if (response.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
        } else {
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }
                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }
        }

    }

    return (
        <>
            <div className='w-100'>
                <div
                    className="d-flex align-items-center text-primary cursor-pointer fw-bold mb-3"
                    onClick={() => push('/masuk')}>
                    <Arrow />
                    <div className="ms-2">Kembali</div>
                </div>
                <p className="fw-bold fs-4 mb-2">Atur Ulang Kata Sandi</p>
                {/* <div className="d-inline-block my-3 " style={{ width: '160px', height: '160px', backgroundColor: '#F0F2F5' }}>

                </div> */}
                <div className='font-sm d-flex mb-3'>
                    <div className='me-2 text-secondary'>
                        Silakan isi email Anda yang sudah terdaftar lalu kami
                        <br />
                        akan mengirim intruksi untuk mengatur ulang kata sandi</div>
                </div>
                <div className='mb-3'>
                    <InputField
                        id='email'
                        name='email'
                        placeholder='Masukan Email Anda'
                        onChange={handleChange}
                        label='Email'
                        type="email"
                        value={email}
                    />
                    {message && <div className='text-primary'>{message}</div>}
                </div>
                <div className="d-grid gap-2">
                    <button className="btn btn-primary font-sm" type="button" onClick={handleSubmit}>Kirim</button>
                </div>
            </div >
        </>
    )
}

export default LupaPassword;