import React from 'react'
import { Spinner } from 'react-bootstrap';
import { defaultImageCar } from '../../utils/defaultValue';

const ResultLelang = ({ status, dataAuction }) => {
    return (
        <>  <div className="com-modal-bg"></div>
            <div className='com-modal d-flex flex-center'>
                <div className='com-modal-dialog'>
                    <div className='com-modal-content'>
                        <div className='winner-auction'>
                            {
                                status === 'LOADING' ? (
                                    <>
                                        <div className='winner-auction-body text-center'>
                                            <div className='py-4 mb-3'>
                                                <Spinner animation="grow" />
                                            </div>
                                            <div>Mohon tunggu, kami sedang memilih pemenangnya</div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='text-center pt-3 fs-5 fw-bold'>
                                            Lot telah berakhir
                                        </div>
                                        <div className='winner-auction-body'>
                                            <div className='auction-unit mb-3'>
                                                <div className='auction-unit-image m-2' style={{ background: `url("${dataAuction?.pictures?.length > 0 ? dataAuction?.pictures[0]?.image_url : defaultImageCar}")`, height: '90px', width: '120px', borderRadius: '12px' }}></div>
                                                <div className='auction-unit-content'>
                                                    <div className='auction-unit-info w-100 d-flex flex-column h-100'>
                                                        <div className='mb-2 text-black fw-bold '>{dataAuction?.unit_name}</div>
                                                        <div>
                                                            <div className={`badge d-inline ${status === 'TERJUAL' ? 'bg-primary' : 'bg-secondary'}`}>{status}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center'>Anda akan segera diarahkan pada lot selanjutnya...</div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultLelang;
