import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
import withAuthProvider from '../../../services/withAuthProvider';
import toast from 'react-hot-toast';
import MoreButton from '../../MoreButton/MoreButton';
import { SkeletonNIPL } from '../../Skeleton/Skeleton';
// import { PrimaryButton } from "../../PrimaryButton/PrimaryButton"

const dataDummy = [
  { status: 'success' },
  { status: 'secondary' },
  { status: 'warning' },
  { status: 'info' },
  { status: 'danger' },
];
const buttonList = [
  { active: true, status: 'Semua', slug: '' },
  // { active: false, status: 'Tidak Aktif', slug: 'INACTIVE' },
  { active: false, status: 'Permintaan Refund', slug: 'REQUEST-RETURN' },
  { active: false, status: 'Refund', slug: 'RETURNED' },
  { active: false, status: 'Wanprestasi', slug: 'WANPRESTASI' },
];

const initialPaginate = {
  page: 1,
  total: 1,
};

const Riwayat = () => {
  const [tab, setTab] = useState([...buttonList]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });

  const ref = useRef(true);

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const checkStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Aktif';
      case 'WINNING':
        return 'Menang';
      case 'REQUEST-RETURN':
        return 'Menunggu Konfirmasi';
      case 'RETURNED':
        return 'Refund';
      case 'WANPRESTASI':
        return 'Wanprestasi';
      default:
        return 'Tidak Aktif';
    }
  };
  const checkStyle = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'primary';
      case 'WINNING':
        return 'success';
      case 'REQUEST-RETURN':
        return 'warning';
      case 'RETURNED':
        return 'info';
      case 'WANPRESTASI':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const getDataList = async () => {
    setIsLoading(true);
    const found = tab.find((a) => a.active);
    const params = {
      page: paginate.page,
      page_size: 5,
      status: found.slug,
    };
    const response = await withAuthProvider.getDataWithAuth(`api/nipl`, params);
    if (response.status === 200) {
      const res = response.data.data;
      const newData = res.data.map((el) => ({
        ...el,
        label: checkStatus(el.status),
        style: checkStyle(el.status),
      }));
      if (paginate.page !== 1) {
        setData((prev) => [...prev, ...newData]);
      } else {
        setData(newData);
      }
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
      // console.log(response.response);
    }
  };
  useEffect(() => {
    if (!ref.current) {
      setPaginate((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [tab]);

  useEffect(() => {
    getDataList().finally(() => setIsLoading(false));
    ref.current = false;
  }, [paginate.page, tab]);

  const handleClick = (index) => {
    setTab((prev) =>
      prev.map((el, idx) => ({
        ...el,
        active: idx === index,
      }))
    );
  };
  return (
    <div>
      <div className='d-flex font-sm' style={{ marginBottom: '10px' }}>
        {tab.map(({ status, active }, idx) => (
          <div
            key={idx}
            className={`cursor-pointer disable-copy d-flex me-2 align-items-center border border-2 p-2 rounded-custom
                            ${active ? 'primary-button-new border-primary' : ''}
                            `}
            onClick={() => handleClick(idx)}
          >
            {status}
          </div>
        ))}
      </div>
      <div className='overflow-auto' style={{ maxHeight: '600px' }}>
        <div className='d-flex flex-column' style={{ gap: '10px' }}>
          {isLoading
            ? new Array(3).fill(1).map((el, idx) => (
                <div className='border rounded-custom p-3 font-sm' key={idx}>
                  <SkeletonNIPL />
                </div>
              ))
            : data.map((el, idx) => (
                <div
                  className='border rounded-custom p-3 font-sm d-flex justify-content-between align-items-center'
                  key={idx}
                >
                  <div>
                    <div className='fw-bold mb-1'>
                      {el?.unit_type?.name} - {el?.code} {el?.nipl}
                      <span className='value mx-1 disable-copy'>
                        <span className={`badge-status ${el.style}`}>
                          {el?.label}
                        </span>
                      </span>
                    </div>
                    {el?.buyer?.is_master_auction !== 1 || el.location ? (
                      <div className='text-secondary'>
                        {el?.location?.name},{' '}
                        {el?.auction?.date_start && el?.auction?.time_start
                          ? format(
                              new Date(`${el?.auction?.date_start}`),
                              'dd MMM yyyy'
                            )
                          : '-'}{' '}
                        {el?.auction?.date_start && el?.auction?.time_start
                          ? el?.auction?.time_start?.substr(0, 5)
                          : '-'}
                      </div>
                    ) : (
                      <>
                        {el?.buyer?.is_master_auction === 1 && (
                          <span className='badge-pumper'>Pumper</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          {data.length === 0 && isLoading === false && (
            <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
              Tidak ada NIPL
            </div>
          )}
        </div>
      </div>
      {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
    </div>
  );
};

export default Riwayat;
