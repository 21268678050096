import { ProsedurIcon1, ProsedurIcon10, ProsedurIcon11, ProsedurIcon12, ProsedurIcon13, ProsedurIcon14, ProsedurIcon15, ProsedurIcon2, ProsedurIcon3, ProsedurIcon4, ProsedurIcon5, ProsedurIcon6, ProsedurIcon7, ProsedurIcon8, ProsedurIcon9 } from "../../../assets/icons/ProsedurIcons"

const liveBidding = [
    {
        icon: ProsedurIcon1,
        title: 'Cari kendaraan & cek jadwal lelang via website',
        value: 'Daftar lot kendaraan objek lelang dapat dilihat dan diunduh dari website. Jadwal lelang di semua kota pun dapat dicek via website Caready.',
    },
    {
        icon: ProsedurIcon2,
        title: 'Cek kondisi kendaraan lelang Caready',
        value: 'Calon peserta lelang dapat melakukan pengecekan langsung objek lelang dilokasi Caready pada saat Open House H – 2 sampai dengan hari H lelang, atau dapat melakukan pengecekan melalui website Caready',
    },
    {
        icon: ProsedurIcon6,
        title: 'Registrasi & beli Nomor Induk Peserta Lelang (NIPL)',
        value: 'Registrasi dengan melengkapi data diri Anda, kemudian lakukan pembelian NPL secara online melalui website Caready. Pembelian NIPL dapat dilakukan dengan membayarkan uang jaminan lelang atau deposit lelang melalui Virtual Account yang dikirimkan Caready atau melalui mesin Edc di loket Caready.',
    },
    {
        icon: ProsedurIcon3,
        title: 'Ikuti lelang dan BID unit lelang anda.',
        value: 'Peserta dapat mengikuti proses penawaran dari mana saja melalui website Caready secara online dan juga melalui system proxybid yang tersedia, peserta yang hadir dilokasi lelang Caready juga dapat mengikuti proses penawaran secara onsite memakai kartu BID. Peserta dengan penawaran harga tertinggi dapat memenangkan objek lelang.',
    },
    {
        icon: ProsedurIcon4,
        title: 'Pelunasan/Pengembalian Biaya',
        value: 'Jika peserta menjadi pemenang lelang, pembayaran atas unit yang dimenangkan wajib dilakukan selambatnya 5 hari kerja setelah lelang. Namun jika kalah, biaya pembelian NiPL (deposit) akan kembali 100% ke nomor rekening terdaftar, selambatnya 3 hari kerja setelah lelang.',
    },
    {
        icon: ProsedurIcon5,
        title: 'Pengambilan Kendaraan dan Dokumen',
        value: 'Peserta dapat mengambil kendaraan beserta dokumennya setelah melunasi pembayaran selambatnya 5 hari kerja setelah lelang.',
    },
]

const proxyBid = [
    {
        icon: ProsedurIcon6,
        title: 'Registrasi dan beli nomor peserta lelang (NIPL )',
        value: 'Daftar diri anda melalui website www.caready.co.id lengkapi data kalian serta menunggu verifikasi dari team kami , kemudian pembelian NIPL secara Online.',
    },
    {
        icon: ProsedurIcon2,
        title: 'Cari kendaraan/ barang dan cek jadwal lelang',
        value: 'Cek kendaraan dan jadwal lelang di website caready sesuai keinginan',
    },
    {
        icon: ProsedurIcon7,
        title: 'Pilih Lelang yang diinginkan',
        value: 'Pilih jadwal  live action yang ada di website caready, perserta dapat bergabung sesuai jadwal kendaraan/ barang yang sudah berlangsung.',
    },
]

const regularBid = [
    {
        icon: ProsedurIcon1,
        title: 'Cari unit dan lihat jadwal di website',
        value: 'Cari unit sesuai keinginan dan tentukan jadwal lelang yang diikuti',
    },
    {
        icon: ProsedurIcon13,
        title: 'Cek kondisi barang sebelum hari Lelang',
        value: 'caready memberikan kebebasan kepada peserta lelang untuk cek unit sebelum hari Lelang',
    },
    {
        icon: ProsedurIcon8,
        title: 'Sudah registrasi secara online website caready',
        value: 'Ketika sudah menentukan unit yang dicari, peserta Lelang wajib registrasi secara online memalui website kami www.caready.co.id jika terjadi kendala bisa hubungi CS kami',
    },
    {
        icon: ProsedurIcon3,
        title: 'Ikuti Lelang sesuai jadwal yang sudah di tentukan',
        value: 'Peserta wajib mengikuti Lelang caready dengan jadwal yang sudah di tentukan',
    },
    {
        icon: ProsedurIcon14,
        title: 'Pelunasan/ pengembalian unit',
        value: 'Ketika peserta Lelang mendapatkan  unit yang di inginkan peserta Lelang wajib melunasi sesuai waktu yang sudah di tentukan.',
    },
    {
        icon: ProsedurIcon15,
        title: 'Pengambilan unit',
        value: 'Pelunasan dan pembayaran sudah terpenuhi peserta Lelang bisa mengambil unit di pool caready',
    },
]

const pelunasan = [
    {
        icon: ProsedurIcon8,
        title: 'Login akun yang terdaftar',
        value: 'Peserta Wajib Login menggunakan akun yang terdaftar di website caready.',
    },
    {
        icon: ProsedurIcon9,
        title: 'Cek Riwayat Transaksi',
        value: 'Peserta login',
    },
]

const titipJual = [
    {
        icon: ProsedurIcon10,
        title: 'Memberikan informasi MOU',
        value: 'Staff Caready  akan menjelaskan syarat dan ketentuan.',
    },
    {
        icon: ProsedurIcon12,
        title: 'Melengkapi syarat-syarat kendaraan',
        value: 'Penitip Unit wajib melengkapi surat-surat kendaraaan yang akan di ikut sertakan lelang',
    },
    {
        icon: ProsedurIcon11,
        title: 'Proses Inspeksi',
        value: 'Penitip unit wajib mengikuti prosedur inspeksi sebelum jadwal lelang',
    },
]

export {
    liveBidding,
    proxyBid,
    regularBid,
    pelunasan,
    titipJual
}