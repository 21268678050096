import React, { useState, useEffect } from 'react'
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import withAuth from '../../../services/withAuthProvider';
import withoutAuth from '../../../services/withoutAuthProvider';
import toast from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function RekeningBankContent() {
    const [dataBank, setDataBank] = useState([]);

    const getBank = async () => {
        const response = await withoutAuth.getDataWithoutAuth('/api/bank', {})
        if (response.status === 200) {
            setDataBank(response.data.data.data)
        } else {
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    const getBankAccount = async () => {
        const response = await withAuth.getDataWithAuth('/api/account/bank-account', {})
        if (response.status === 200) {
            const dataBankAccount = response.data.data;
            if (dataBankAccount !== undefined && dataBankAccount.length !== 0) {
                setValue("bank_id", dataBankAccount.bank_id)
                setValue("account_number", dataBankAccount.account_number)
                setValue("account_name", dataBankAccount.account_name)
                setValue("bank_branch_name", dataBankAccount.bank_branch_name)

            }
        } else {
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    useEffect(() => {
        getBank();
        getBankAccount();

    }, [])

    // form validation rules 
    const validationSchema = Yup.object().shape({
        bank_id: Yup.string().required("Silahkan memilih bank"),
        account_number: Yup.string().required("Silahkan mengisi nomor rekening"),
        account_name: Yup.string().required("Silahkan mengisi nama pemilik rekening"),
        bank_branch_name: Yup.string().required("Silahkan mengisi kantor cabang bank"),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        const dataBankAccount = new FormData();
        const found = dataBank.find(bank => bank.id === parseInt(data.bank_id))
        dataBankAccount.append("bank_id", data.bank_id);
        dataBankAccount.append("bank_name", found.name);
        dataBankAccount.append("account_number", data.account_number);
        dataBankAccount.append("account_name", data.account_name);
        dataBankAccount.append("bank_branch_name", data.bank_branch_name);

        const loading = toast.loading('Mengirimkan...');
        const response = await withAuth.postDataWithAuth('/api/account/bank-account/update', dataBankAccount)
        if (response.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
        } else {
            toast.dismiss(loading);
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    return (
        <>
            <h4 className='sub-title'>Detail Informasi Bank</h4>
            <Row>
                <Col md={6}>
                    <Form className="form-caready" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="form-group" controlId="bank">
                            <Form.Label className='required'>Bank</Form.Label>
                            <Form.Select
                                placeholder="Pilih Bank"
                                {...register('bank_id')}
                            >
                                <option value="">Pilih Bank</option>
                                {dataBank.map((bank) => (
                                    <option key={bank.id} value={bank.id}>
                                        {bank.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.bank_id?.message && <span className='error-message'>{errors.bank_id?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group" controlId="no_rekening">
                            <Form.Label className='required'>No Rekening</Form.Label>
                            <FormControl
                                placeholder=""
                                aria-label=""
                                aria-describedby="basic-addon1"
                                {...register('account_number')}
                            />
                            {errors.account_number?.message && <span className='error-message'>{errors.account_number?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group" controlId="pemilik_rekenning">
                            <Form.Label className='required'>Atas Nama</Form.Label>
                            <FormControl
                                placeholder=""
                                aria-label=""
                                aria-describedby="basic-addon1"
                                {...register('account_name')}
                            />
                            {errors.account_name?.message && <span className='error-message'>{errors.account_name?.message}</span>}
                        </Form.Group>

                        <Form.Group className="form-group" controlId="kantor_cabang">
                            <Form.Label className='required'>Kantor Cabang</Form.Label>
                            <FormControl
                                placeholder=""
                                aria-label=""
                                aria-describedby="basic-addon1"
                                {...register('bank_branch_name')}
                            />
                            {errors.bank_branch_name?.message && <span className='error-message'>{errors.bank_branch_name?.message}</span>}
                        </Form.Group>

                        <Button className="button-login mb-3" style={{ display: "block !important", width: "100%" }} variant="primary" type="submit">
                            Simpan
                        </Button>

                    </Form>
                </Col>
            </Row>
        </>
    )
}
