import { createSlice } from '@reduxjs/toolkit'
// Slice
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLogged: !!localStorage.getItem("token"),
        user: null,
        token: localStorage.getItem("token")
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;
            state.isLogged = true;
            state.token = action.payload.token;
            localStorage.setItem("token", action.payload.token);
        },
        logout: (state, action) => {
            state.user = null;
            state.token = null;
            state.isLogged = false;
            localStorage.removeItem("token")
        },
    },
});

export const userSelector = state => state.authReducer.user;
export const tokenSelector = state => state.authReducer.token;
export const isLoggedSelector = state => state.authReducer.isLogged;

export const { actions: actionsAuth, reducer } = authSlice;