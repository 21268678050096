import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import IconYoutube from '../../assets/icons/IconYoutube';
import IconInstagram from '../../assets/icons/IconInstagram';
import IconFacebook from '../../assets/icons/IconFacebook';
import IconLinkedin from '../../assets/icons/IconLinkedin';
import Location from '../../assets/icons/contactIcon/Location';
import withoutAuthProvider from '../../services/withoutAuthProvider';
import { replaceEnter } from '../../utils/string';
import {
  SkeletonImage,
  SkeletonLokasiKami,
} from '../../components/Skeleton/Skeleton';
import careadyLocation from '../../assets/images/careadyLocation.webp';

export const socialMedias = [
  {
    image: <IconFacebook />,
    path: '/',
  },
  {
    image: <IconYoutube />,
    path: '/',
  },
  {
    image: <IconInstagram />,
    path: '/',
  },
  {
    image: <IconLinkedin />,
    path: '/',
  },
];

export default function LokasiKami() {
  const alamat =
    'Jl. Raya Narogong No.37, RT.002/RW.007, Bojong Menteng, Kec. Rawalumbu, Kota Bekasi, Jawa Barat 17117';
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const activeMaps = locations.find((el) => el.active);

  const setActive = (idx) => {
    // alert(idx)
    setLocations((prev) =>
      prev.map((el, i) => ({
        ...el,
        active: i === idx,
      }))
    );
  };

  const getLocation = async () => {
    const params = {};
    const response = await withoutAuthProvider.getDataWithoutAuth(
      `api/location`,
      params
    );
    if (response.status === 200) {
      const { data } = response.data.data;
      setLocations(
        data.map((el, idx) => ({
          ...el,
          name_show: replaceEnter(el.detail),
          active: idx === 0 ? true : false,
        }))
      );
    } else {
      // console.log(response.response);
    }
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    getLocation().finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [activeMaps]);

  return (
    <>
      <div
        className='page h-100 w-100'
        style={{ paddingTop: '20px', paddingBottom: '40px' }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
        >
          <div
            style={{
              textAlign: 'center',
              maxWidth: '440px',
              marginBottom: '20px',
            }}
          >
            <h2 style={{ fontWeight: 600 }}>Lokasi Kami</h2>
            <div>{alamat}</div>
          </div>
        </div>
        <section>
          <Container>
            <Row>
              <Col md={{ span: 6 }} className='d-block d-sm-none'>
                <div className='box-border' style={{ height: '600px' }}>
                  {isLoading ? (
                    <SkeletonImage />
                  ) : (
                    activeMaps !== undefined && (
                      <iframe
                        title='lokasi caready'
                        className='h-100 w-100'
                        id='gmap_canvas'
                        src={activeMaps?.google_maps}
                        frameBorder='0'
                        scrolling='no'
                        marginHeight='0'
                        marginWidth='0'
                      ></iframe>
                    )
                  )}
                </div>
              </Col>
              <Col md={{ span: 6 }}>
                <div className='contact-us'>
                  {isLoading
                    ? new Array(3).fill(1).map((el, idx) => (
                        <div className='location-tab disable-copy' key={idx}>
                          <SkeletonLokasiKami />
                        </div>
                      ))
                    : locations.map((location, idx) => {
                        const nameSplit = location?.name
                          ?.toUpperCase()
                          ?.split(' ');
                        return (
                          <div
                            className={`location-tab disable-copy ${
                              location?.active ? 'location-tab-active' : ''
                            }`}
                            key={idx}
                            onClick={() => setActive(idx)}
                          >
                            <div
                              className='d-none d-sm-block overflow-hidden position-relative'
                              style={{
                                width: '180px',
                                height: '180px',
                                borderRadius: '10px',
                                background: '#CCC',
                              }}
                            >
                              {!isLoading && (
                                <img
                                  src={careadyLocation}
                                  alt=''
                                  className='h-100 w-100'
                                />
                              )}
                              <div
                                className='position-absolute bottom-0 pb-4 text-center w-100 fw-bold disable-copy'
                                style={{ fontSize: '0.75rem' }}
                              >
                                <span style={{ color: '#EB4F48' }}>
                                  {nameSplit[0] || ''}{' '}
                                </span>
                                <span style={{ color: '#40519F' }}>
                                  {nameSplit[1] || ''}
                                </span>
                              </div>
                            </div>
                            <div className='location-info'>
                              <h4
                                style={{
                                  fontWeight: 600,
                                  marginBottom: '10px',
                                }}
                              >
                                {location?.name}
                              </h4>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: location?.name_show,
                                }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  marginTop: '10px',
                                }}
                              >
                                <Location />
                                <label
                                  style={{
                                    marginLeft: '2px',
                                    fontWeight: 600,
                                    color: 'red',
                                  }}
                                >
                                  Tunjukkan Peta
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </Col>
              <Col md={{ span: 6 }} className='d-none d-md-block'>
                <div className='box-border' style={{ height: '600px' }}>
                  {isLoading ? (
                    <SkeletonImage />
                  ) : (
                    activeMaps !== undefined && (
                      <iframe
                        title='lokasi caready'
                        className='h-100 w-100'
                        id='gmap_canvas'
                        src={activeMaps?.google_maps}
                        frameBorder='0'
                        scrolling='no'
                        marginHeight='0'
                        marginWidth='0'
                      ></iframe>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}
