import React from 'react'
import { useParams, useHistory } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap'
import LiveBidding from '../../components/TabContent/Prosedur/LiveBidding';
import ProxyBidding from '../../components/TabContent/Prosedur/ProxyBidding';
import RegularBidding from '../../components/TabContent/Prosedur/RegularBidding';
import PelunasanBidding from '../../components/TabContent/Prosedur/PelunasanBidding';
import TitipJualBidding from '../../components/TabContent/Prosedur/TitipJualBidding';

export default function Prosedur() {
    const { push } = useHistory();
    const { part } = useParams();

    const handleClick = (path) => {
        push(path)
    }

    const selectedTab = () => {
        switch (part) {
            case "live-bidding-online-auction":
                return <LiveBidding />
            case "proxy-bidding-online-auction":
                return <ProxyBidding />
            case "regular-bidding-onsite-auction":
                return <RegularBidding />
            case "cara-pelunasan-lelang":
                return <PelunasanBidding />
            case "titip-jual-lelang":
                return <TitipJualBidding />
            default:
                return <LiveBidding />
        }
    }

    return (
        <>
            <div className='w-100 h-100 page'>
                <section>
                    <Container>
                        <Row>
                            <Col md={{ span: 3 }}>
                                <div className="box-border mt-15">
                                    <div className={`nav-item-vertical cursor-pointer ${part === "live-bidding-online-auction" ? "active" : ""}`} onClick={() => handleClick("/prosedur/live-bidding-online-auction")}>Live Bidding Online Auction</div>
                                    <div className={`nav-item-vertical cursor-pointer ${part === "proxy-bidding-online-auction" ? "active" : ""}`} onClick={() => handleClick("/prosedur/proxy-bidding-online-auction")}>Proxy Bidding Online Auction</div>
                                    <div className={`nav-item-vertical cursor-pointer ${part === "regular-bidding-onsite-auction" ? "active" : ""}`} onClick={() => handleClick("/prosedur/regular-bidding-onsite-auction")}>Regular Bidding Onsite Auction</div>
                                    <div className={`nav-item-vertical cursor-pointer ${part === "cara-pelunasan-lelang" ? "active" : ""}`} onClick={() => handleClick("/prosedur/cara-pelunasan-lelang")}>Cara Pelunasan Lelang</div>
                                    <div className={`nav-item-vertical cursor-pointer ${part === "titip-jual-lelang" ? "active" : ""}`} onClick={() => handleClick("/prosedur/titip-jual-lelang")}>Cara Titip Jual Lelang</div>
                                </div>
                            </Col>
                            <Col md={{ span: 9 }}>
                                <div className="box-border mt-15 mb-3">
                                    {selectedTab()}
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </div>
        </>
    )
}
