import DetailUnitLayout from "../containers/Layouts/CustomLayout/DetailUnitLayout.js";
import MainLayout from "../containers/Layouts/MainLayout/MainLayout.js";
import DetailUnit from "../pages/DetailUnit/DetailUnit.js";
import JadwalLelangHariIni from "../pages/JadwalLelang/JadwalLelangHariIni.js";
import JadwalLelangMendatang from "../pages/JadwalLelang/JadwalLelangMendatang.js";
import UnitLelang from "../pages/UnitLelang/UnitLelang.js";
import InfoCaready from "../pages/InfoCaready/InfoCaready.js";
import InfoCareadyDetail from "../pages/InfoCaready/InfoCareadyDetail.js";
import TentangKami from "../pages/TentangKami/TentangKami.js";
import HubungiKami from "../pages/HubungiKami/HubungiKami.js";
import TitipLelang from "../pages/TitipLelang/TitipLelang.js";
import Inspeksi from "../pages/Inspeksi/Inspeksi.js";
// import Checkout from "../pages/Checkout/Checkout.js";
import Prosedur from "../pages/Prosedur/Prosedur.js";
import LokasiKami from "../pages/LokasiKami/LokasiKami.js";
import FAQ from "../pages/FAQ/FAQ.js";
import LiveAuctionPublic from "../pages/LiveAuctionPublic/LiveAuctionPublic";
import FullscreenLayout from "../containers/Layouts/FullscreenLayout.js/FullscreenLayout";
import JadwalLelangQuartal from "../pages/JadwalLelang/JadwalLelangQuartal.js";
import SyaratDanKetentuan from "../pages/SyaratDanKetentuan/SyaratDanKetentuan.js";
import KebijakanPrivasi from "../pages/KebijakanPrivasi/KebijakanPrivasi.js";

export const routeList = [
  {
    path: "/prosedur/:part",
    component: Prosedur,
    layout: MainLayout,
  },
  {
    path: "/prosedur/",
    component: Prosedur,
    layout: MainLayout,
  },
  {
    path: "/laporan-inspeksi/:id",
    component: Inspeksi,
    layout: MainLayout,
  },
  {
    path: "/hubungi-kami",
    component: HubungiKami,
    layout: MainLayout,
  },
  {
    path: "/lokasi-kami",
    component: LokasiKami,
    layout: MainLayout,
  },
  {
    path: "/faq",
    component: FAQ,
    layout: MainLayout,
  },
  {
    path: "/tentang-kami",
    component: TentangKami,
    layout: MainLayout,
  },
  {
    path: "/syarat-dan-ketentuan",
    component: SyaratDanKetentuan,
    layout: MainLayout,
  },
  {
    path: "/kebijakan-privasi",
    component: KebijakanPrivasi,
    layout: MainLayout,
  },
  {
    path: "/info-caready-detail/:slug",
    component: InfoCareadyDetail,
    layout: MainLayout,
  },
  {
    path: "/info-caready",
    component: InfoCaready,
    layout: MainLayout,
  },
  {
    path: "/titip-lelang",
    component: TitipLelang,
    layout: MainLayout,
  },
  {
    path: "/detail-unit",
    component: DetailUnit,
    layout: DetailUnitLayout,
  },
  {
    path: "/unit-lelang/:id",
    component: DetailUnit,
    layout: DetailUnitLayout,
  },
  {
    path: "/unit-lelang",
    component: UnitLelang,
    layout: MainLayout,
  },
  {
    path: "/jadwal-lelang-mendatang",
    component: JadwalLelangMendatang,
    layout: MainLayout,
  },
  {
    path: "/jadwal-lelang-hari-ini",
    component: JadwalLelangHariIni,
    layout: MainLayout,
  },
  {
    path: "/jadwal-lelang-quartal",
    component: JadwalLelangQuartal,
    layout: MainLayout,
  },
  {
    path: "/live-auction-public/:id",
    component: LiveAuctionPublic,
    layout: FullscreenLayout,
  },
  {
    path: "/",
    component: UnitLelang,
    layout: MainLayout,
  },
  {
    component: UnitLelang,
    layout: MainLayout,
  },
];
