import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import ArrowRight from '../../assets/icons/ArrowRightSearch';
import withoutAuth from '../../services/withoutAuthProvider';

function FilterUnitLelang({
  years,
  silinders,
  search,
  setSearch,
  filterValue,
  setFilterValue,
  filterTag,
  setFilterTag,
  handleSearch,
  removeTag,
  unitType,
}) {
  // data list
  const [unitTypeId, setUnitTypeId] = useState(filterValue.unit_type_id);
  const [makers, setMakers] = useState([]);
  const [models, setModels] = useState([]);
  const [modelTypes, setModelTypess] = useState([]);
  const [transmissions, setTransmissions] = useState([]);
  const [colors, setColors] = useState([]);
  const [auctions, setAuctions] = useState([]);

  const [series, setSeries] = useState([]);

  const merkBars = [
    {
      name: 'unit_maker_id',
      child: makers,
      type: 'select',
      label: 'Maker',
    },
    {
      name: 'unit_model_id',
      child: models,
      type: 'select',
      label: 'Model',
    },
    {
      name: 'engine_capacity',
      child: silinders,
      type: 'select',
      label: 'Silinder',
    },
    {
      name: 'unit_model_type_id',
      child: modelTypes,
      type: 'select',
      label: 'Tipe',
    },
    {
      name: 'transmission_id',
      child: transmissions,
      type: 'select',
      label: 'Transmisi',
    },
    {
      name: 'color_id',
      child: colors,
      type: 'select',
      label: 'Warna',
    },
    {
      name: 'year',
      child: years,
      type: 'select',
      label: 'Tahun',
    },
    {
      name: 'auction_id',
      child: auctions,
      type: 'select',
      label: 'Jadwal',
    },
  ];

  const handleChangeUnitType = (e) => {
    const { value } = e.target;
    const newValue = value ? parseInt(value) : value;
    setUnitTypeId(newValue);
  };

  const handlerFilterData = (e) => {
    const { name, value } = e.target;
    const findArray = merkBars.find((el) => el.name === name);
    if (findArray !== undefined) {
      const foundArr = findArray.child.find((a) => a.id === parseInt(value));
      if (foundArr !== undefined) {
        setFilterValue((prev) => ({
          ...prev,
          search: '',
          [name]: value,
        }));
        const tagFound = filterTag.find((b) => b.name === name);
        if (tagFound) {
          setFilterTag((prev) =>
            prev.map((d) => ({
              ...d,
              label: d.name === name ? foundArr?.name : d.label,
            }))
          );
        } else {
          setFilterTag((prev) => [
            ...prev,
            {
              name: name,
              label: foundArr?.name,
            },
          ]);
        }
      } else {
        const foundData = filterTag.find((a) => a.name === name);
        const nameF = foundData.name;
        setFilterValue((prev) => ({
          ...prev,
          [nameF]: '',
        }));
        setFilterTag((prev) => prev.filter((el) => el.name !== nameF));
      }
    }
  };

  const getDataAuction = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/auction',
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data.data);
      setAuctions(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataUnitMaker = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-maker',
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data.data);
      setMakers(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataUnitModel = async () => {
    const params = {
      unit_maker_id: filterValue?.unit_maker_id,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-model',
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data);
      setModels(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataUnitModelType = async () => {
    const params = {
      unit_maker_id: filterValue?.unit_model_id,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-model-type',
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data);
      setModelTypess(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataColors = async () => {
    const response = await withoutAuth.getDataWithoutAuth('/api/color', {});
    if (response.status === 200) {
      // console.log(response.data.data);
      setColors(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataTransmission = async () => {
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/transmission',
      {}
    );
    if (response.status === 200) {
      // console.log(response.data.data);
      setTransmissions(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  useEffect(() => {
    // getDataUnitMaker();
    // getDataColors();
    // getDataTransmission();
    //getDataAuction();
  }, []);

  useEffect(() => {
    if (filterValue.unit_maker_id !== '') {
      getDataUnitModel();
      setFilterValue((prev) => ({
        ...prev,
        unit_model_id: '',
        unit_model_type_id: '',
      }));
      setFilterTag((prev) =>
        prev
          .filter((a) => a.name !== 'unit_model_id')
          .filter((b) => b.name !== 'unit_model_type_id')
      );
    }
  }, [filterValue.unit_maker_id]);

  useEffect(() => {
    if (filterValue.unit_model_id !== '') {
      getDataUnitModelType();
      setFilterValue((prev) => ({
        ...prev,
        unit_model_type_id: '',
      }));
      setFilterTag((prev) =>
        prev.filter((b) => b.name !== 'unit_model_type_id')
      );
    }
  }, [filterValue.unit_model_id]);

  const submitFilter = (e) => {
    e.preventDefault();
    handleSearch(unitTypeId);
  };

  return (
    <div className='filterUnitLelang'>
      <form onSubmit={submitFilter}>
        <Container>
          <Row>
            <Col span={12}>
              <div className='box-big-filter'>
                <InputGroup className='box-filter'>
                  <Form.Select
                    className='filter-type'
                    value={unitTypeId}
                    onChange={(e) => handleChangeUnitType(e)}
                    placeholder='Pilih Type'
                  >
                    <option value=''>Semua</option>
                    {unitType.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    ))}
                  </Form.Select>
                  <FormControl
                    className='filter-search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button className='filter-button' type='submit'>
                    Cari <ArrowRight className='filter-icon' />
                  </Button>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <div className='filter-options'>
            {/*merkBars.map((el, idx) => (
                <div className="filter-item" key={`${el.name} ${idx}`}>
                  <Form.Select
                    onChange={(e) => handlerFilterData(e)}
                    placeholder={el.label}
                    name={el.name}
                    value={filterValue[el.name]}
                  >
                    <option value="">{el.label}</option>
                    {
                      el.child.map((a, b) => (
                        <option key={`${a.name}-${b}`} value={a.id}>{a.name}</option>
                      ))
                    }
                  </Form.Select>
                </div>
              ))*/}
          </div>
        </Container>
      </form>
    </div>
  );
}

export default FilterUnitLelang;
