import { BrowserRouter as Router, Switch } from "react-router-dom";
import { authList } from "./routing/authList";
import CustomRoute from "./routing/CustomRoute/CustomRoute";
import { routeList } from "./routing/routeList";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./config/store";
import "./App.css";
import "@mdi/font/css/materialdesignicons.css";
import ProtectedRoute from "./routing/ProtectRoute/ProtectedRoute";
import { toast, Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import withoutAuthProvider from "./services/withoutAuthProvider";

function App() {
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const toastError = (message) => {
    return toast.error(message, {
      id: "error-message",
      duration: 3000,
    });
  };

  const changeFavicon = async () => {
    const exclusive_seller_slug = subdomain;

    // get company icon
    const response = await withoutAuthProvider.getDataWithoutAuth(
      `/api/auction/seller/${exclusive_seller_slug}`
    );
    if (response?.status === 200) {
      const data = response?.data.data;

      // set to favicon
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = data.exclusive_logo_file_url;
    } else {
      toastError(response?.data.message);
    }
  };

  useEffect(() => {
    changeFavicon();
  });

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              {authList.map((route, idx) => (
                <ProtectedRoute
                  key={idx}
                  path={route.path}
                  // exact={route.exact}
                  layout={route.layout}
                  isLogged={route.isLogged}
                  page={route.component}
                />
              ))}
              {routeList.map((route, idx) => (
                <CustomRoute
                  key={idx}
                  path={route.path}
                  // exact={route.exact}
                  layout={route.layout}
                  page={route.component}
                />
              ))}
            </Switch>
            <Toaster />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default Sentry.withProfiler(App);
