import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Service from '../../components/Services/Service'
import maps from '../../assets/images/maps.png'
import careadyicon from '../../assets/images/caready-icon.png'
import { useHistory } from 'react-router-dom'
import TentangCaready from '../../components/Section/TentangCaready'
import { TentangKami1, TentangKami2, TentangKami3, TentangKami4, TentangKami5 } from '../../assets/images/tentangKami'
import InfoSignUp from '../../components/InfoSignUp/InfoSignUp'

const visi = 'Menjadi balai lelang yang utama di Indonesia dan mengedepankan kualitas dan kuantitas keberhasilan lelang dalam transaksi jual beli';
const misi = 'Memberikan Pelayanan Terbaik, bertanggung jawab dan dapat di percaya bagi pengguna bidder';
const perjalanan_caready = 'PT Balai Lelang Caready berdiri pada tahun 2019 merupakan perusahaan joint venture antara PT Blue Bird Tbk, Mitsubishi HC Capital Inc, dan PT Takari Kokoh Sejahtera. yang memiliki bisnis dalam proses membeli dan menjual barang atau jasa kendaraan, alat berat maupun barang yang dilelang secara Online maupun Offiline. PT Balai Lelang Caready menerapkan “best practice” lelang yang memberikan kemudahan bagi pemilik untuk menjual mobilnya dengan waktu yang singkat serta dengan harga jual yang tinggi. Begitu pun untuk para pembeli yang mendapatkan keuntungan dari pembelian unit lelang yang jauh dari harga di pasaran. Sistem lelang di Caready tentunya merupakan sistem lelang yang aman dan terpercaya. Caready hadir menjadi balai lelang kendaraan yang mampu memberikan layanan terbaik bagi para seller baik perorangan, lising maupun perusahaan untuk melakukan transaksi lelang berbagai macam kendaraan, barang dan lainnya dengan mudah, cepat, aman dan nyaman. Dengan didukung tenaga profesional, Caready mampu menjadi Balai lelang terbaik melalui system. penjualan yang tepat, tempat penyimpanan yang luas untuk menyimpan mobil, motor dan barang lainnya sehingga sangat aman.';
const youtubeLink = 'https://www.youtube.com/embed/LPXNqiNOhOg'
const layanan = [
    {
        icon: TentangKami1,
        title: 'Lelang Secara Online',
        value: 'Lelang online ini dilakukan di situs www.caready.co.id setiap peserta dapat melakukan secara daring dan dimanapun yang terdapat koneksi internet.',
    },
    {
        icon: TentangKami2,
        title: 'Titip Lelang',
        value: 'Kami menyediakan titip Lelang dengan Fee Kompetitif Caready menyelenggarakan lelang 5 hari dalam 1 minggu dengan kota yang berbeda Yang di laksanakan setiap hari Selasa, Rabu, Kamis, Jumat dan Sabtu.',
    },
    {
        icon: TentangKami3,
        title: 'Transaksi aman dan mudah',
        value: 'Pembayaran transaksi Caready sangat aman dan mudah dikarenakan melalui virtual account ',
    },
    {
        icon: TentangKami4,
        title: 'Free Mobilisasi',
        value: 'Caready memiliki layanan Gratis penjemputan dan antar ke penempatan lelang ke bidder yang sudah join.',
    },
    {
        icon: TentangKami5,
        title: 'Info unit yang transparan',
        value: 'Caready akan memberitahukan kepada pelanggan tentang info unit yang ditanyakan secara transparan dan lengkap.',
    },
]

export default function TentangKami() {
    const { push } = useHistory();
    return (
        <div className='page h-100'>
            <TentangCaready />
            <section className='overview pt-0'>
                <Container>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <div className="title">
                                Siapa Kami?
                            </div>
                            <div className="desc mb-2">
                                Sebagai salah satu balai lelang kendaraan terbesar, PT Balai Lelang Caready menerapkan best practice lelang yang memberikan banyak kemudahan dan benefits kepada para peserta lelang. Dengan dukungan sistem, sarana, dan prasarana yang lengkap dan mutakhir, para peserta lelang sebagai pembeli misalnya, dapat melakukan pendaftaran secara offline maupun online, melakukan pembayaran melalui virtual account sehingga  proses verifikasi dapat dilakukan secara instan. Peserta lelang juga dapat melakukan penawaran secara sederhana, di mana penjual dapat melakukan "titip lelang"; dimulai dari proses pengambilan kendaraan yang akan dilelang, proses taksasi, hingga laporan penjualan unit yang dapat diperoleh secara cepat setelah pelaksanaan lelang.
                            </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <div className="center-horizontal-vertical mb-2" style={{ height: "100%" }}>
                                <img className='image-overview' alt="Caready Logo" src={careadyicon} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='visi-misi'>
                <Container>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <div className='visi-misi-item'>
                                <div className="title">
                                    Visi Kami
                                </div>
                                <div className="desc">
                                    {visi}
                                </div>
                            </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <div className='visi-misi-item'>
                                <div className="title">
                                    Misi Kami
                                </div>
                                <div className="desc">
                                    {misi}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='tentang-caready'>
                <Container>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <div className="d-flex h-100 align-items-center">
                                {/* <img src={careadySplash2} alt="" className="w-100 h-100" /> */}
                                <iframe width="420" height="345" className='w-100' src={youtubeLink} />
                            </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <div className="center-horizontal-vertical" style={{ height: "100%" }}>
                                <div>
                                    <div className="title">
                                        Perjalanan Caready
                                    </div>
                                    <div className="desc ">
                                        {perjalanan_caready}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='locations' style={{ background: `url(${maps})` }}>
                <Container>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <div className="title">Caready saat ini tersedia di:</div>
                        </Col>
                        <Col md={{ span: 3 }}><div className="location">Surabaya</div></Col>
                        <Col md={{ span: 3 }}><div className="location">Bekasi</div></Col>
                        <Col md={{ span: 3 }}><div className="location">Semarang</div></Col>
                        <Col md={{ span: 3 }}><div className="location">Palembang</div></Col>
                    </Row>
                </Container>
            </section>
            <section className='layanan-caready'>
                <Container>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <div className="title">Layanan Kami</div>
                        </Col>
                        {
                            layanan.map((lay, idx) => (
                                <Col md={{ span: 4 }} key={`layanan-${idx}`}>
                                    <Service title={lay.title} desc={lay.value} icon={lay.icon} />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </section>
            <InfoSignUp />
        </div>
    )
}
