import { useParams, useHistory } from 'react-router-dom';
import { LogoutIcon } from '../../assets/icons/settingIcon';
import User from '../../assets/icons/User';
import FavoritContent from '../../components/TabContent/FavoritContent';
import NiplContent from '../../components/TabContent/NiplContent';
import NotifikasiContent from '../../components/TabContent/NotifikasiContent';
import ProxyBidContent from '../../components/TabContent/ProxyBidContent';
import SettingContent from '../../components/TabContent/SettingContent';
import TransaksiContent from '../../components/TabContent/TransaksiContent';
import { initialDataSetting } from './dataSetting';
import { useSelector, useDispatch } from 'react-redux';
import { actionsAuth, userSelector } from '../../redux/auth.slice';
import { format } from 'date-fns';
import withAuth from '../../services/withAuthProvider';
import { useEffect, useState } from 'react';
import '../../assets/css/component.css';

const TabContainer = ({ label, icon: Icon, isCount, count, handleClick }) => {
  return (
    <div
      className='d-flex justify-content-between align-items-center px-3 py-2 cursor-pointer'
      onClick={handleClick}
    >
      <div className='d-flex align-items-center'>
        <Icon />
        <div className='ms-2'>{label}</div>
      </div>
      {isCount && (
        <div
          className='rounded px-2 font-xs'
          style={{ backgroundColor: '#EBEBEB' }}
        >
          {count}
        </div>
      )}
    </div>
  );
};

const Setting = () => {
  const { push } = useHistory();
  const { type } = useParams();
  const user = useSelector(userSelector);
  const [counter, setCounter] = useState([]);
  const dispatch = useDispatch();

  const handleClick = (path) => {
    push(path);
  };

  const handleLogout = async () => {
    const response = await withAuth.postDataWithAuth('/api/logout', {});
    if (response.status === 200) {
      dispatch(actionsAuth.logout({}));
      push('/');
    }
  };

  const selectedTab = () => {
    switch (type) {
      case 'profile':
        return <SettingContent></SettingContent>;
      case 'transaksi':
        return <TransaksiContent></TransaksiContent>;
      case 'notifikasi':
        return <NotifikasiContent></NotifikasiContent>;
      case 'favorit':
        return <FavoritContent />;
      case 'nipl':
        return <NiplContent />;
      case 'proxy-bid':
        return <ProxyBidContent />;
      default:
        return <SettingContent />;
    }
  };

  const getCounter = async () => {
    const response = await withAuth.getDataWithAuth(`/api/account/counter`, {});
    if (response?.status === 200) {
      const { data } = response.data;
      setCounter(data);
    } else {
      // console.log(response.response);
    }
  };

  useEffect(() => {
    getCounter();
  }, []);

  return (
    <div className='w-100 h-100'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-md-3 mb-3 fw-semibold'>
            <div
              className='card overflow-hidden rounded-custom'
              style={style.shadow}
            >
              <div id='bg-gradient' className='py-1'></div>
              <div className='d-flex align-items-center border-bottom py-2 px-3'>
                <div style={{ width: '51px', height: '51px' }}>
                  <div
                    className='d-inline-block rounded-circle'
                    style={{
                      backgroundColor: '#E4E7EB',
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    <div className='d-flex justify-content-center h-100 align-items-center'>
                      <User className='text-secondary' />
                    </div>
                  </div>
                </div>
                <div className='ms-2'>
                  <div className='fw-bold fs-6 d-flex flex-wrap align-items-center'>
                    <span
                      style={{
                        marginRight: user?.is_master_auction === 1 ? 5 : 0,
                      }}
                    >
                      {user?.name}
                    </span>
                    {user?.is_master_auction === 1 && (
                      <span className='badge-pumper'>Pumper</span>
                    )}
                  </div>
                  <div className='font-xs text-secondary'>
                    Bergabung sejak{' '}
                    {format(new Date(user.created_at), 'dd MMM yyyy')}
                  </div>
                </div>
              </div>
              <div className='border-bottom font-sm py-1'>
                {initialDataSetting.map((el, idx) => (
                  <div key={idx}>
                    <TabContainer
                      label={el.label}
                      count={counter[el.counter_identifier] || 0}
                      icon={el.icon}
                      isCount={el.isCount}
                      handleClick={() => handleClick(el.path)}
                    />
                  </div>
                ))}
              </div>
              <div className='text-primary font-sm py-1'>
                <TabContainer
                  label='Logout'
                  icon={LogoutIcon}
                  handleClick={handleLogout}
                />
              </div>
            </div>
          </div>
          <div className='col-md-9'>
            <div className='card rounded-custom' style={style.shadow}>
              {selectedTab()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;

const style = {
  shadow: {
    backgroundColor: '#FFF',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px',
  },
};
