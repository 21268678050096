import { format } from "date-fns";
import { id } from 'date-fns/esm/locale';

export const replaceEnter = (str) => {
    str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    return str;
}

export const locDateFormatter = (location_name, date, time) => {
    const str = `${location_name}, ${format(date, 'dd MMM yyyy', { locale: id })} ${time.substr(0, 5)}`
    return str;
}

export const dateFormatter = (date, notFull = false) => {
    let format_date = 'dd MMM yyyy, HH:mm'
    if (notFull) {
        format_date = 'dd MMM yyyy';
    }
    const str = format(new Date(date), format_date, { locale: id })
    return str;
}