import { format } from 'date-fns';
import { bca } from '../../../assets/images/beliNIPLicon';
import { useEffect, useState } from 'react';

const SettingTransaksiBayar = (props) => {
  const detail = props.data;
  const [PPN, setPPN] = useState({
    show: false,
  });

  useEffect(() => {
    if (detail.ppn_buyer !== null && detail.ppn_buyer_include !== null)
      setPPN({
        show: true,
        include: detail.ppn_buyer_include,
        value: detail.ppn_buyer,
        total: detail.ppn_buyer_rp,
        include_change: detail.ppn_buyer_fee,
      });
  }, [detail]);

  var payment_number = '';
  if (detail?.payment_bank_code !== null) {
    if (detail?.payment_bank_code === 'mandiri') {
      payment_number = detail?.payment_bill_key;
    } else {
      payment_number = detail?.payment_bank_virtual_account;
    }
  }
  return (
    <div className='font-sm'>
      <div className='fw-semibold pb-2 mb-3'>
        <div className='fs-6 mb-1'>Total Tagihan</div>
        <div className='fs-5'>
          Rp{' '}
          {detail?.insufficient
            ? Number(detail?.insufficient).toLocaleString()
            : '-'}
        </div>
      </div>
      <div
        className='p-2 rounded font-xs mb-3 text-center'
        style={{ backgroundColor: 'rgba(207, 23, 1, 0.05)' }}
      >
        <div>
          Bayar Sebelum:{' '}
          <span className='fw-semibold'>
            {detail?.expiry_date
              ? format(new Date(`${detail.expiry_date}`), 'dd MMM yyyy, HH:mm')
              : '-'}
          </span>
        </div>
      </div>
      <div className='card p-2 mb-3 rounded-custom'>
        <div className='d-flex pb-1 border-bottom justify-content-between mb-1'>
          <div>{detail?.payment_bank_name || ''} Virtual Account</div>
          <div>{/* <img src={bca} alt="bca" /> */}</div>
        </div>
        <div className='d-flex justify-content-between mb-1'>
          <div className='text-secondary'>No. Virtual Account</div>
          <div>{payment_number || ''}</div>
        </div>
      </div>

      <div className='py-2 mb-2'>
        <div className='font-sm fw-semibold mb-2'>Rincian Pembayaran</div>
        <div className='card p-2 mb-3 rounded-custom'>
          <div className='fw-semibold font-sm mb-1'>
            Lot{' '}
            {detail?.stock_unit?.lot_number &&
            detail?.auction?.auction_lane_name
              ? detail?.auction?.auction_lane_name +
                detail?.stock_unit?.lot_number
              : '-'}{' '}
            -{' '}
            {detail?.stock_unit?.unit_name ||
              detail?.stock_unit?.year +
                ' ' +
                detail?.stock_unit?.unit_maker?.name +
                ' ' +
                detail?.stock_unit?.unit_model?.name}
          </div>
          <div className='mb-1 text-secondary'>
            {detail?.auction?.location_name || '-'} -{' '}
            {detail?.auction?.date_start && detail?.auction?.time_start
              ? format(
                  new Date(`${detail?.auction?.date_start}`),
                  'dd MMM yyyy'
                )
              : '-'}{' '}
            {detail?.auction?.date_start && detail?.auction?.time_start
              ? detail?.auction?.time_start?.substr(0, 5)
              : '-'}{' '}
            WIB
          </div>
          <div className='d-flex justify-content-between'>
            <div className='text-secondary'>
              1 x{' '}
              {detail?.final_price
                ? Number(detail?.final_price).toLocaleString()
                : '-'}
            </div>
            <div>
              Rp{' '}
              {detail?.final_price
                ? Number(detail?.final_price).toLocaleString()
                : '-'}
            </div>
          </div>
        </div>
        <div className='card p-2 rounded-custom'>
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-secondary'>Harga Terbentuk Lelang</div>
            <div>
              Rp{' '}
              {detail?.final_price
                ? Number(detail?.final_price).toLocaleString()
                : '-'}
            </div>
          </div>
          {PPN.show && (
            <div className='d-flex justify-content-between mb-1'>
              <div className='text-secondary'>PPN {PPN.value}%</div>
              <div>Rp {Number(PPN.total).toLocaleString()}</div>
            </div>
          )}

          <div className='d-flex justify-content-between mb-1'>
            <div className='text-secondary'>Deposit Lelang</div>
            <div>
              (Rp{' '}
              {detail?.deposit ? Number(detail?.deposit).toLocaleString() : '-'}
              )
            </div>
          </div>
          <div className='d-flex justify-content-between pb-2 border-bottom mb-1'>
            <div className='text-secondary'>Biaya Admin</div>
            <div>
              Rp{' '}
              {detail?.admin_fee
                ? Number(detail?.admin_fee).toLocaleString()
                : '-'}
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div className='text-secondary'>Total Bayar</div>
            <div>
              Rp{' '}
              {detail?.sub_total
                ? Number(detail?.sub_total).toLocaleString()
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingTransaksiBayar;
