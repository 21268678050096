import { useState } from "react";
import { useHistory } from "react-router-dom";
import Headphone from "../../assets/icons/Headphone"

export default function HeaderTop() {
    const { push } = useHistory();
    const [isShow, setIsShow] = useState(false);

    const rightmenu = [
        { title: 'Hubungi Kami', path: '/hubungi-kami' },
        { title: 'Prosedur', path: '/prosedur/live-bidding-online-auction' },
        { title: 'Tentang Kami', path: '/tentang-kami' },
    ];

    const clickPath = (path) => {
        push(path);
    }

    return (
        <nav className="text-primary" style={{ backgroundColor: '#F0F2F5' }}>
            <div className="container py-2 font-xs fw-semibold">
                <div className="d-flex fw-bold justify-content-between align-items-center">
                    <div
                        className="d-flex justify-content-between align-items-center cursor-pointer"
                        onClick={() => setIsShow(!isShow)}>
                        <Headphone />
                        <p className="mb-0 ms-2">Bantuan</p>
                    </div>
                    {isShow ? (
                        <div className="position-absolute" style={{ backgroundColor: "#FFFFFF", marginTop: "120px", padding: "10px", borderRadius: "8px" }}>
                            <div className="menu-dropdown" onClick={() => clickPath("/faq")}>FAQ</div>
                            <div className="menu-dropdown" onClick={() => clickPath("/lokasi-kami")}>Lokasi Cabang</div>
                        </div>
                    ) : ""}
                    <div className="d-flex items-align-center">
                        {
                            rightmenu.map((el, idx) => (
                                <div
                                    className="cursor-pointer mx-2"
                                    key={`right-menu-${idx + 1}`}
                                    onClick={() => clickPath(el.path)}>
                                    <p className="mb-0">{el.title}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}