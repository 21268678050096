import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PasswordField } from "../../components/PasswordField/PasswordField";
import withoutAuth from "../../services/withoutAuthProvider";
import toast from 'react-hot-toast';

const initialData = {
    password: '',
    password2: '',
}

const ResetPassword = () => {
    const [data, setData] = useState({ ...initialData });
    const { token, email } = useParams();
    const { push } = useHistory();
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async () => {
        const loading = toast.loading('Mengirimkan...');
        const dataReset = {
            token: token,
            email: email,
            password: data.password,
            password_confirmation: data.password2,
        }

        const response = await withoutAuth.postDataWithoutAuth('/api/password/reset', dataReset)
        if (response.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            push("/masuk");
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }
                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }
        }


    }

    useEffect(async () => {
        const response = await withoutAuth.getDataWithoutAuth(`/api/password/check/${token}/${email}`, {})
        if (response.status !== 200) {
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }, []);

    return (
        <>
            <div className='w-100'>
                <p className="fw-bold fs-4 mb-2">Buat Kata Sandi Baru</p>
                {/* <div className="d-inline-block my-3 " style={{ width: '160px', height: '160px', backgroundColor: '#F0F2F5' }}>

                </div> */}
                <div className='font-sm d-flex mb-3'>
                    <div className='me-2 text-secondary'>
                        Kata sandi baru Anda haruslah berbeda dari kata sandi
                        <br />
                        Anda yang lama</div>
                </div>
                <div className='mb-3'>
                    <PasswordField
                        id='password'
                        name='password'
                        placeholder=''
                        onChange={handleChange}
                        label='Kata Sandi Baru'
                        value={data.password}
                    />
                </div>
                <div className='mb-3'>
                    <PasswordField
                        id='password2'
                        name='password2'
                        placeholder=''
                        onChange={handleChange}
                        label='Ulangi Kata Sandi Baru'
                        value={data.password2}
                    />
                </div>
                <div className="d-grid pt-3 gap-2">
                    <button className="btn btn-primary font-sm" type="button" onClick={handleSubmit}>Simpan</button>
                </div>
            </div >
        </>
    )
}

export default ResetPassword;