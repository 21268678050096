import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Subscribe from '../../components/Section/Subscribe';
import withoutAuthProvider from '../../services/withoutAuthProvider';
import { SkeletonInfoCareadyDetail } from '../Skeleton/InfoCareadyDetail/InfoCareadyDetail';
import { InfoCareadyContent } from './detail/InfoCareadyContent';
import { InfoCareadyNotFound } from './detail/InfoCareadyNotFound';

export default function InfoCareadyDetail() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(true);
  const [data, setData] = useState({});

  const getData = async () => {
    const response = await withoutAuthProvider.getDataWithoutAuth(
      `/api/blog/${slug}`,
      {}
    );
    if (response.status === 200) {
      setData(response.data.data);
    } else {
      if (response.status === 404) {
        setIsFound(false);
      }
      // console.log(response.response)
    }
  };

  useEffect(() => {
    getData().finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <SkeletonInfoCareadyDetail />
      ) : isFound ? (
        <InfoCareadyContent data={data} />
      ) : (
        <InfoCareadyNotFound />
      )}
      <Subscribe></Subscribe>
    </>
  );
}
