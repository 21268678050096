import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import withAuthProvider from '../../../services/withAuthProvider';
import { SkeletonNIPL } from '../../Skeleton/Skeleton';
import MoreButton from '../../MoreButton/MoreButton';
import toast from 'react-hot-toast';

const initialPaginate = {
  page: 1,
  total: 1,
};

const NiplActive = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getDataList = async () => {
    const params = {
      page: paginate.page,
      page_size: 5,
    };
    const response = await withAuthProvider.getDataWithAuth(
      `api/nipl/active`,
      params
    );
    if (response.status === 200) {
      const res = response.data.data;
      setData((prev) => [...prev, ...res.data]);
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
      // console.log(response.response);
    }
  };

  useEffect(() => {
    getDataList().finally(() => setIsLoading(false));
  }, [paginate.page]);

  return (
    <div className='overflow-auto' style={{ maxHeight: '600px' }}>
      <div className='d-flex flex-column' style={{ gap: '10px' }}>
        {isLoading
          ? new Array(3).fill(1).map((el, idx) => (
              <div className='border rounded-custom p-3 font-sm' key={idx}>
                <SkeletonNIPL />
              </div>
            ))
          : data.map((nipl, idx) => (
              <div className='border rounded-custom p-3 font-sm' key={idx}>
                <div className='fw-bold mb-1'>
                  {nipl?.unit_type?.name} - {nipl?.code} {nipl?.nipl}
                </div>

                {nipl?.buyer?.is_master_auction !== 1 || nipl.location ? (
                  <div className='text-secondary'>
                    {nipl?.location?.name},{' '}
                    {nipl?.auction?.date_start && nipl?.auction?.time_start
                      ? format(
                          new Date(`${nipl?.auction?.date_start}`),
                          'dd MMM yyyy'
                        )
                      : '-'}{' '}
                    {nipl?.auction?.date_start && nipl?.auction?.time_start
                      ? nipl?.auction?.time_start?.substr(0, 5)
                      : '-'}
                  </div>
                ) : (
                  <>
                    {nipl?.buyer?.is_master_auction === 1 && (
                      <span className='badge-pumper'>Pumper</span>
                    )}
                  </>
                )}
              </div>
            ))}
        {data.length === 0 && isLoading === false && (
          <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
            Tidak ada NIPL
          </div>
        )}
      </div>
      {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
    </div>
  );
};

export default NiplActive;
