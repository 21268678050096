import { FavoritIcon, NIPLIcon, NotifikasiIcon, PengaturanIcon, ProxyBidIcon, TransaksiIcon } from "../../assets/icons/settingIcon";

export const initialDataSetting = [
    {
        label: 'Pengaturan',
        isCount: false,
        icon: PengaturanIcon,
        count: 0,
        counter_identifier:null,
        path: '/pengaturan/profile',
    },
    {
        label: 'Transaksi',
        isCount: false,
        icon: TransaksiIcon,
        count: 0,
        counter_identifier:null,
        path: '/pengaturan/transaksi',
    },
    {
        label: 'Notifikasi',
        isCount: true,
        icon: NotifikasiIcon,
        count: 0,
        counter_identifier:'unread_notifications',
        path: '/pengaturan/notifikasi',
    },
    {
        label: 'Favorit',
        isCount: false,
        icon: FavoritIcon,
        count: 0,
        counter_identifier:null,
        path: '/pengaturan/favorit',
    },
    {
        label: 'NIPL',
        isCount: true,
        icon: NIPLIcon,
        count: 0,
        counter_identifier:'nipl',
        path: '/pengaturan/nipl',
    },
    {
        label: 'Proxy Bid',
        isCount: true,
        icon: ProxyBidIcon,
        count: 0,
        counter_identifier:'proxy_bid',
        path: '/pengaturan/proxy-bid',
    },
]