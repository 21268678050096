import React, { useState } from "react";
import {
    Tabs,
    Tab,
    Row,
    Col,
    Form,
    FormControl,
    Button,
    InputGroup,
} from "react-bootstrap";
import ProfilContent from "./SubContent/ProfilContent";
import RekeningBankContent from "./SubContent/RekeningBankContent";
import UbahKataSandi from "./SubContent/UbahKataSandi";

export default function SettingContent() {
    const [key, setKey] = useState("profile");

    return (
        <>
            <div className="tab-content-caready">
                <h3 className="title">Pengaturan</h3>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 tab-navigation"
                >
                    <Tab eventKey="profile" title="Profile">
                        <ProfilContent />
                    </Tab>
                    <Tab eventKey="rekening-bank" title="Rekening Bank">
                        <RekeningBankContent />
                    </Tab>
                    <Tab eventKey="ubah-kata-sandi" title="Ubah Kata Sandi">
                        <UbahKataSandi />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}
