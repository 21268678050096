import React, { useState, useEffect, useMemo } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import CardUnitJadwal from "../../components/CardUnit/CardUnitJadwal";
import FilterJadwalLelangQuartal from "../../components/Filters/FilterJadwalLelangQuartal";
import "../../assets/css/component.css";
import withoutAuth from "../../services/withoutAuthProvider";
import { format } from "date-fns";
import MoreButton from "../../components/MoreButton/MoreButton";
import { useHistory } from "react-router-dom";
import { SkeletonJadwal } from "../../components/Skeleton/Skeleton";
import { actionsPage } from "../../redux/page.slice";
import { useDispatch } from "react-redux";

const initialPaginate = {
  page: 1,
  page_size: 12,
  total: 1,
  location_id: "",
  unit_type_id: "",
  quartal: 1,
  year: new Date().getFullYear(),
};

const quartal = [
  {
    id: 1,
    label: "Q1 (Januari - Maret)",
    shortlabel: "Q1 (Jan - Mar)",
    start_date: "01-01",
    end_date: "03-31",
  },
  {
    id: 2,
    label: "Q2 (April - Juni)",
    shortlabel: "Q2 (Apr - Jun)",
    start_date: "04-01",
    end_date: "06-30",
  },
  {
    id: 3,
    label: "Q3 (Juli - September)",
    shortlabel: "Q3 (Jul - Sep)",
    start_date: "07-01",
    end_date: "09-30",
  },
  {
    id: 4,
    label: "Q4 (Oktober - Desember)",
    shortlabel: "Q4 (Okt - Des)",
    start_date: "10-01",
    end_date: "12-31",
  },
];

export default function JadwalLelangQuartal() {
  const date = useMemo(() => format(new Date(), "yyyy-MM-dd"), []);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const [detail, setDetail] = useState({
    id: 1,
    label: "Q1 (Januari - Maret)",
    shortlabel: "Q1 (Jan - Mar)",
    start_date: "01-01",
    end_date: "03-31",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaginate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getLocation = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/location",
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data.data);
      setLocations(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };
  const getUnitTypes = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/unit-type",
      params
    );
    if (response.status === 200) {
      // console.log(response.data.data.data);
      setUnitTypes(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const getDataJadwal = async () => {
    console.log("get jadwal");
    const params = {
      page: 1,
      page_size: paginate.page_size,
      exclusive_seller_slug: subdomain,
    };
    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    if (paginate.quartal !== "") {
      params.quartal = paginate.quartal;
    }
    if (paginate.year !== "") {
      params.year = paginate.year;
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response.status === 200) {
      const dat = response.data.data;
      // console.log(dat)
      await setUnits(dat.data);
      await setPaginate((prev) => ({
        ...prev,
        total: dat.last_page,
      }));
      setIsLoading(false);
      // } else {
      //     toastError(response.data.message)
    }
    // setLoading(false);
  };

  const fetchMoreData = async () => {
    const params = {
      page: paginate.page,
      page_size: paginate.page_size,
    };
    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    if (paginate.quartal !== "") {
      params.quartal = paginate.quartal;
    }
    if (paginate.year !== "") {
      params.year = paginate.year;
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response.status === 200) {
      const dat = response.data.data;
      await setUnits((prev) => [...prev, ...dat.data]);
      // } else {
      //     toastError(response.data.message)
    }
  };

  useEffect(() => {
    getLocation();
    getUnitTypes();
  }, []);

  useEffect(() => {
    if (paginate.page !== 1) {
      fetchMoreData();
    }
  }, [paginate.page]);

  useEffect(() => {
    console.log("change detail");
    const data = quartal.find((data) => data.id === parseInt(paginate.quartal));
    if (data) {
      setDetail(data);
    }
  }, [paginate.quartal]);

  useEffect(() => {
    getDataJadwal();
    if (paginate.page !== 1) {
      setPaginate((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [
    paginate.location_id,
    paginate.unit_type_id,
    paginate.quartal,
    paginate.year,
  ]);

  return (
    <div className="jadwalLelang">
      <div>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <FilterJadwalLelangQuartal
                show={1}
                data={paginate}
                locations={locations}
                unitTypes={unitTypes}
                quartal={quartal}
                handleChange={handleChange}
              />
              <div className="group w-100 filter-schedule">
                <div className="title-schedule-section">
                  {detail?.label} {paginate?.year}
                </div>

                <div className="d-flex w-100-md-auto flex-md-row flex-column">
                  <div className="jadwal-lelang-option">
                    <Form.Select
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#092540",
                      }}
                      placeholder="Seri"
                      name="location_id"
                      onChange={handleChange}
                      value={paginate?.location_id}
                      required
                    >
                      <option value="">Kota</option>
                      {locations.map((el, idx) => (
                        <option key="idx" value={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="jadwal-lelang-option">
                    <Form.Select
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#092540",
                      }}
                      placeholder="Seri"
                      name="unit_type_id"
                      onChange={handleChange}
                      value={paginate?.unit_type_id}
                      required
                    >
                      <option value="">Objek Lelang</option>
                      {unitTypes.map((el, idx) => (
                        <option key="idx" value={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "16px" }}>
                {isLoading ? (
                  new Array(8).fill(1).map((el, idx) => (
                    <div
                      className="col-6 col-md-3 mb-4"
                      key={`skeleton jadwal-${idx}`}
                    >
                      <SkeletonJadwal />
                    </div>
                  ))
                ) : units.length > 0 ? (
                  units.map((el, idx) => (
                    <div className="col-6 col-md-3 mb-4" key={idx}>
                      <CardUnitJadwal
                        id={el?.id}
                        judul={el?.name || "-"}
                        tanggal={el?.date_start}
                        waktu={`${el?.time_start?.substr(
                          0,
                          5
                        )} - ${el?.time_finish?.substr(0, 5)} WIB`}
                        lokasi={el?.location?.name || "-"}
                        live={el?.is_started}
                        onClick={() => {
                          dispatch(actionsPage.setPage({ page: 0 }));
                          push("/unit-lelang", {
                            dataFilter: {
                              auction_id: el?.id,
                              unit_type_id: el?.unit_type?.id,
                            },
                          });
                        }}
                        unit_type_image={el?.unit_type?.banner_url}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-secondary text-center mt-5">
                    Tidak ada jadwal lelang
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {paginate.page !== paginate.total && (
            <MoreButton onClick={loadMore} />
          )}
        </Container>
      </div>
    </div>
  );
}
