import { PrimaryButton } from '../../components/PrimaryButton/PrimaryButton';
import { Button, Form, Spinner } from 'react-bootstrap';
import { SelectInput } from '../../components/SelectInput/SelectInput';
import {
  actionTransaction,
  transactionUnitSelector,
} from '../../redux/transaction.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import withAuth from '../../services/withAuthProvider';
import { useEffect, useState } from 'react';
import ModalLayout from '../../containers/Layouts/ModalLayout/ModalLayout';
import SettingTransaksiBayar from '../../components/Modal/SettingTransaksiBayar/SettingTransaksiBayar';
import PaymentHistory from '../../components/Modals/PaymentHistory';
import { banks } from '../../helpers/constans';

const wording =
  'Pemenang lelang yang tidak melunasi pembayaran sesuai tenggat waktu yang telah ditetapkan akan dinyatakan wanprestasi (kemenangan batal). Uang jaminan pun otomatis hangus.';

export const RightContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const detail = useSelector(transactionUnitSelector);
  const [loadingForm, setLoadingForm] = useState(false);
  const [PPN, setPPN] = useState({
    show: false,
  });
  const dispatch = useDispatch();
  const [isOpenPaymentHistory, setIsOpenPaymentHistory] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalPaymentHistory = () => {
    setIsOpenPaymentHistory(true);
  };

  const closeModalPaymentHistory = () => {
    setIsOpenPaymentHistory(false);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    payment_method: Yup.string().required('Silahkan memilih metode pembayaran'),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getTransaction = async () => {
    const imageURL = [];
    const response = await withAuth.getDataWithAuth(
      '/api/trx-invoice-unit/transaction/unit/' + detail?.id,
      {}
    );
    console.log('response', response);
    if (response.status === 200) {
      const dataTransaction = response.data.data;

      dispatch(actionTransaction.setUnit({ data: dataTransaction }));
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });
    } else {
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const dataTransaction = new FormData();

    dataTransaction.append('payment_bank_code', data.payment_method);

    setLoadingForm(true);
    const loading = toast.loading('Mengirimkan...');
    const response = await withAuth.postDataWithAuth(
      '/api/trx-invoice-unit/' + detail?.id + '/pay',
      dataTransaction
    );
    if (response.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });

      getTransaction();
      openModal();
      reset();
      setLoadingForm(false);
    } else {
      toast.dismiss(loading);
      if (
        typeof response.data.message === 'object' &&
        response.data.message !== null &&
        !Array.isArray(response.data.message)
      ) {
        var data_message = '';
        for (var key in response.data.message) {
          if (response.data.message.hasOwnProperty(key)) {
            data_message += response.data.message[key];
          }
        }
        toast.error(data_message, {
          id: 'error-message',
          duration: 3000,
        });
      } else {
        toast.error(response.data.message, {
          id: 'error-message',
          duration: 3000,
        });
      }
      setLoadingForm(false);
    }
    e.target.reset();
  };

  useEffect(() => {
    if (detail.ppn_buyer !== null && detail.ppn_buyer_include !== null)
      setPPN({
        show: true,
        include: detail.ppn_buyer_include,
        value: detail.ppn_buyer,
        total: detail.ppn_buyer_rp,
        include_change: detail.ppn_buyer_fee,
      });
  }, [detail]);

  return (
    <div className='font-sm'>
      <div className='fw-semibold pb-2'>
        <div className='fs-6 mb-1'>Ringkasan</div>
        <div className='card p-2 rounded-custom mt-3'>
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-secondary'>Harga Terbentuk Lelang</div>
            <div>
              Rp{' '}
              {detail?.final_price
                ? Number(detail?.final_price).toLocaleString()
                : '-'}
            </div>
          </div>
          {PPN.show && (
            <div className='d-flex justify-content-between mb-1'>
              <div className='text-secondary'>PPN {PPN.value}%</div>
              <div>Rp {Number(PPN.total).toLocaleString()}</div>
            </div>
          )}
          <div className='d-flex justify-content-between mb-1'>
            <div className='text-secondary'>Deposit Lelang</div>
            <div>
              (Rp{' '}
              {detail?.deposit ? Number(detail?.deposit).toLocaleString() : '-'}
              )
            </div>
          </div>
          {detail?.service_fee ? (
            <div className='d-flex justify-content-between mb-1'>
              <div className='text-secondary'>Biaya Layanan</div>
              <div>Rp {Number(detail?.service_fee).toLocaleString()}</div>
            </div>
          ) : (
            ''
          )}
          <div className='d-flex justify-content-between  mb-1'>
            <div className='text-secondary'>Biaya Admin</div>
            <div>
              Rp{' '}
              {detail?.admin_fee
                ? Number(detail?.admin_fee).toLocaleString()
                : '-'}
            </div>
          </div>
          {detail?.royal_costs ? (
            detail?.royal_costs !== 0 ? (
              <div className='d-flex justify-content-between  mb-1'>
                <div className='text-secondary'>Biaya Roya</div>
                <div>
                  Rp{' '}
                  {detail.royal_costs
                    ? Number(detail.royal_costs).toLocaleString()
                    : '-'}
                </div>
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <div
            className='d-flex justify-content-between pt-1 mt-1 border-top'
            style={{ fontSize: 16, fontWeight: 'bold', padding: '8px 0' }}
          >
            <div>Total Tagihan</div>
            {/* <div>Rp {detail?.sub_total ? Number(detail?.sub_total + detail?.service_fee).toLocaleString() : "-"}</div> */}
            <div>
              Rp{' '}
              {detail?.grand_total
                ? Number(detail?.grand_total).toLocaleString()
                : '-'}
            </div>
          </div>
        </div>
      </div>
      {detail?.result?.result_selling !== 'WANPRESTASI' ? (
        <>
          {detail?.insufficient !== 0 && (
            <div className='fw-semibold pb-2 my-3'>
              <div className='font-sm mb-1'>Total / Sisa Tagihan</div>
              <div className='fs-5'>
                Rp{' '}
                {detail?.insufficient
                  ? Number(detail?.insufficient).toLocaleString()
                  : '-'}
              </div>
            </div>
          )}
          {detail?.status !== '' ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {detail?.status !== 'PAID' ? (
                  <div
                    className='p-2 rounded font-sm fw-semibold mb-3 text-center'
                    style={{ backgroundColor: 'rgba(207, 23, 1, 0.05)' }}
                  >
                    <div className='text-primary'>
                      Jangka waktu pembayaran 4 (empat) hari kerja.
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {detail?.over_paid !== 0 ? (
                  <div
                    className='p-2 rounded font-sm fw-semibold mb-3 d-flex justify-content-between'
                    style={{ backgroundColor: 'rgba(207, 23, 1, 0.05)' }}
                  >
                    <div className='text-secondary'>Lebih Bayar</div>
                    <div>
                      Rp{' '}
                      {detail?.over_paid
                        ? Number(detail?.over_paid).toLocaleString()
                        : '-'}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className='py-2 mb-2'>
                  {/* {(detail?.payment_bank_name === null) && (detail?.payment_bank_virtual_account === null) && (detail?.status !== "PAID") ? (
                                            <div className="mb-3">
                                                <div className="fw-semibold mb-2 required">Metode Pembayaran</div>
                                                <Controller
                                                    control={control}
                                                    name="payment_method"
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <SelectInput
                                                            options={banks}
                                                            optionValue="id"
                                                            optionLabel="name"
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                                {errors.payment_method?.message && <span className='error-message'>{errors.payment_method?.message}</span>}
                                            </div>
                                        ) : ""} */}
                  <div className='d-flex mb-3'>
                    {/* {detail?.payment_bank_code === null &&
                    detail?.payment_bank_virtual_account === null &&
                    detail?.status !== 'PAID' ? (
                      <Button
                        className='button-login mb-3 fw-bold'
                        style={{ display: 'block !important', width: '100%' }}
                        variant='primary'
                        type='submit'
                        disabled={loadingForm}
                      >
                        {loadingForm ? (
                          <>
                            <Spinner animation='border' size='sm' />
                            Bayar..
                          </>
                        ) : (
                          'Bayar'
                        )}
                      </Button>
                    ) : (
                      ''
                    )} */}

                    {
                      // detail?.payment_bank_name !== null &&
                      // detail?.payment_bank_virtual_account !== null &&
                      detail?.status !== 'PAID' && (
                        <Button
                          className='button-login mb-3 fw-bold'
                          style={{ display: 'block !important', width: '100%' }}
                          variant='primary'
                          type='button'
                          onClick={openModal}
                        >
                          Bayar
                        </Button>
                      )
                    }
                  </div>
                  <div
                    className='btn-payment-history cursor-pointer mb-3'
                    onClick={openModalPaymentHistory}
                  >
                    <div>Riwayat Pembayaran</div>
                    <svg
                      width='8'
                      height='14'
                      viewBox='0 0 8 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5.17192 7.00072L0.221924 2.05072L1.63592 0.636719L7.99992 7.00072L1.63592 13.3647L0.221924 11.9507L5.17192 7.00072Z'
                        fill='#1E1E1F'
                      />
                    </svg>
                  </div>
                  {detail?.status !== 'PAID' ? (
                    <div className='text-center text-secondary'>{wording}</div>
                  ) : (
                    ''
                  )}
                </div>
              </Form>
              <ModalLayout
                show={isOpen}
                handleClose={closeModal}
                title='Menunggu Pembayaran'
                className=''
              >
                <SettingTransaksiBayar data={detail} />
              </ModalLayout>
              <ModalLayout
                show={isOpenPaymentHistory}
                handleClose={closeModalPaymentHistory}
                title={'Riwayat Pembayaran'}
                className='modal-payment-history'
              >
                <PaymentHistory data={detail} />
              </ModalLayout>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};
