import { defaultBlog, defaultImageCar } from "../../../utils/defaultValue"
import { dateFormatter } from "../../../utils/string"

export const CardInfo = ({
    title,
    image,
    badges,
    date
}) => {
    return (
        <>
            <div className="rounded-custom bg-secondary overflow-hidden" style={{ height: '200px' }}>
                <img src={image || defaultBlog} alt='' className="w-100 h-100" loading="lazy" style={{ objectFit: 'fill' }} />
            </div>
            <div className="d-flex my-3" style={{ gap: '0.5rem' }}>
                {
                    badges.map((el, idx) => (
                        <div className="rounded px-2 font-sm" style={{ backgroundColor: '#EBEBEB' }} key={idx}>
                            {el?.name}
                        </div>
                    ))
                }
            </div>
            <div>
                <div className="fw-semibold hover-primary">{title}</div>
                <div className="text-secondary font-xs">{dateFormatter(date, true)}</div>
            </div>
        </>
    )
}