import React, { useState, useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CardUnitJadwal from "../../components/CardUnit/CardUnitJadwal";
import FilterJadwalLelang from "../../components/Filters/FilterJadwalLelang";
import "../../assets/css/component.css";
import withoutAuth from "../../services/withoutAuthProvider";
import { format } from "date-fns";
import MoreButton from "../../components/MoreButton/MoreButton";
import { useHistory } from "react-router-dom";
import { SkeletonJadwal } from "../../components/Skeleton/Skeleton";
import { useDispatch } from "react-redux";
import { actionsPage } from "../../redux/page.slice";

const initialPaginate = {
  page: 1,
  page_size: 12,
  total: 1,
  location_id: "",
  unit_type_id: "",
};

export default function JadwalLelangHariIni() {
  const date = useMemo(() => format(new Date(), "yyyy-MM-dd"), []);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [paginate, setPaginate] = useState({ ...initialPaginate });

  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaginate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getLocation = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/location",
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setLocations(response.data.data.data);
    } else {
      // console.log(response?.response);
    }
  };
  const getUnitTypes = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/unit-type",
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setUnitTypes(response.data.data.data);
    } else {
      // console.log(response?.response);
    }
  };

  const getDataJadwal = async () => {
    setIsLoading(true);
    const params = {
      exclusive_seller_slug: subdomain,
      page: 1,
      page_size: paginate.page_size,
      start_date: date,
      end_date: date,
    };
    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response?.status === 200) {
      const dat = response.data.data;
      // console.log(dat)
      await setUnits(dat.data);
      await setPaginate((prev) => ({
        ...prev,
        total: dat.last_page,
      }));
      setIsLoading(false);
      // } else {
      //     toastError(response.data.message)
    }
    // setLoading(false);
  };

  const fetchMoreData = async () => {
    const params = {
      page: paginate.page,
      page_size: paginate.page_size,
      start_date: date,
      end_date: date,
    };
    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response?.status === 200) {
      const dat = response.data.data;
      await setUnits((prev) => [...prev, ...dat.data]);
      // } else {
      //     toastError(response.data.message)
    }
  };

  useEffect(() => {
    getLocation();
    getUnitTypes();
  }, []);

  useEffect(() => {
    if (paginate.page !== 1) {
      fetchMoreData();
    }
  }, [paginate.page]);

  useEffect(() => {
    getDataJadwal();
    if (paginate.page !== 1) {
      setPaginate((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [paginate.location_id, paginate.unit_type_id]);

  return (
    <div className="jadwalLelang">
      <div>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <FilterJadwalLelang
                show={1}
                data={paginate}
                locations={locations}
                unitTypes={unitTypes}
                handleChange={handleChange}
              />
              <div className="row" style={{ marginBottom: "16px" }}>
                {isLoading ? (
                  new Array(8).fill(1).map((el, idx) => (
                    <div
                      className="col-6 col-md-3 mb-4"
                      key={`skeleton jadwal-${idx}`}
                    >
                      <SkeletonJadwal />
                    </div>
                  ))
                ) : units.length > 0 ? (
                  units.map((el, idx) => (
                    <div className="col-6 col-md-3 mb-4" key={idx}>
                      <CardUnitJadwal
                        id={el?.id}
                        judul={el?.name || "-"}
                        tanggal={el?.date_start}
                        waktu={`${el?.time_start?.substr(
                          0,
                          5
                        )} - ${el?.time_finish?.substr(0, 5)} WIB`}
                        lokasi={el?.location?.name || "-"}
                        live={el?.is_started}
                        onClick={() => {
                          dispatch(actionsPage.setPage({ page: 0 }));
                          push("/unit-lelang", {
                            dataFilter: {
                              auction_id: el?.id,
                              unit_type_id: el?.unit_type?.id,
                            },
                          });
                        }}
                        unit_type_image={el?.unit_type?.banner_url}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-secondary text-center mt-5">
                    Tidak ada jadwal lelang
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {paginate.page !== paginate.total && (
            <MoreButton onClick={loadMore} />
          )}
        </Container>
      </div>
    </div>
  );
}
