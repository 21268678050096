import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
// import { getRequest } from "../../helpers/request";
// import '../../assets/css/component.css';
import CarouselLayout from '../../containers/Layouts/CarouselLayout/CarouselLayout';
// import SingleUnit from '../../assets/images/single-unit.png'
// import Success from '../../assets/icons/Success'
// import Warning from '../../assets/icons/Warning'
// import ImageInterior from '../../assets/images/inspek-interior.png';
import toast from 'react-hot-toast';
// import { format } from 'date-fns'
import withoutAuth from '../../services/withoutAuthProvider';
import { useParams } from 'react-router-dom';
import ModalLayout from '../../containers/Layouts/ModalLayout/ModalLayout';
import Image from '../../components/Images/Images';
// import PageNotFound from '../Errors/PageNotFound';

const defaultImage =
  'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true';

const initialModal = {
  show: false,
  data: '',
};

export default function Inspeksi() {
  const [report, setReport] = useState([]);
  const [images, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState({ ...initialModal });
  const { id } = useParams();

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const openModal = (img) =>
    setModalState((prev) => ({
      ...prev,
      show: true,
      data: img,
    }));

  const closeModal = () =>
    setModalState((prev) => ({
      ...prev,
      show: false,
    }));

  const imgError = () => {
    setModalState((prev) => ({
      ...prev,
      data: defaultImage,
    }));
  };

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector('.navigation-sticky');
    const scrollTop = window.scrollY;
    scrollTop >= 0
      ? header?.classList.add('is-sticky')
      : header.classList.remove('is-sticky');
  };

  const [visibleSection, setVisibleSection] = useState('informasi-unit');
  const headerRef = useRef(null);

  const informasiunitRef = useRef(null);
  const inspeksiRef = useRef(new Array());
  const sectionRefs = [
    { section: 'informasi-unit', ref: informasiunitRef, idx: '-' },
  ];

  const scrollTo = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section-inspection');
      const navLi = document.querySelectorAll(
        '.navigation-sticky .container ul li'
      );
      var current = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop - 60) {
          current = section.getAttribute('id');
          // console.log(current)
        }
      });

      if (current && current !== visibleSection) {
        setVisibleSection(current);
      } else if (!current && visibleSection) {
        setVisibleSection('informasi-unit');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection]);

  const getReport = async () => {
    const imageURL = [];
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/report-inspection/' + id,
      {}
    );
    if (response?.status === 200) {
      const dataReport = response.data.data;
      if (dataReport?.pictures.length > 0) {
        dataReport.pictures.map((image, i) =>
          imageURL.push({ image: image.image_url })
        );
        setImage(imageURL);
      }
      console.log('dataReport', dataReport);
      setReport(dataReport);
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className='py-5 font-sm text-secondary text-center'>
          <Spinner animation='border' size='sm' />
        </div>
      ) : report ? (
        <div className='page h-100  pb-3'>
          <div className='navigation-sticky is-sticky' ref={headerRef}>
            <Container>
              <ul>
                <li
                  className={`${
                    visibleSection === 'informasi-unit' ? 'active' : ''
                  }`}
                >
                  <a
                    onClick={() => {
                      scrollTo(informasiunitRef.current);
                    }}
                  >
                    Informasi Unit
                  </a>
                </li>
                {report?.unit_inspection?.result_inspection_categories
                  ? report.unit_inspection.result_inspection_categories.map(
                      (category, i) => {
                        return (
                          <li
                            className={`${
                              visibleSection ===
                              category.inspection_category_name
                                .toLowerCase()
                                .replace(/[^\w ]+/g, '')
                                .replace(/ +/g, '-')
                                ? 'active'
                                : ''
                            }`}
                          >
                            <a
                              onClick={() => {
                                scrollTo(
                                  inspeksiRef.current[
                                    category.inspection_category_name
                                      .toLowerCase()
                                      .replace(/[^\w ]+/g, '')
                                      .replace(/ +/g, '-')
                                  ]
                                );
                              }}
                            >
                              {category.inspection_category_name}
                            </a>
                          </li>
                        );
                      }
                    )
                  : ''}
              </ul>
            </Container>
          </div>
          <section
            className='inspeksi'
            id='informasi-unit'
            ref={informasiunitRef}
          >
            <Container>
              <div className='box-border'>
                <h3 className='title-section'>Informasi Unit</h3>
                <Row>
                  <Col md={{ span: 6 }}>
                    <CarouselLayout dots={true}>
                      {images.length > 0 ? (
                        images.slice(0, 5).map((image, i) => (
                          <div>
                            <div
                              className='with-background inspeksi-slider'
                              style={
                                image.image
                                  ? { background: `url("${image.image}")` }
                                  : {}
                              }
                            ></div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <div
                            className='with-background inspeksi-slider'
                            style={
                              defaultImage
                                ? { background: `url(${defaultImage.image})` }
                                : {}
                            }
                          ></div>
                        </div>
                      )}
                    </CarouselLayout>
                    <div className='title-section'>Catatan</div>
                    <div className='desc'>{report.document_note || '-'}</div>
                  </Col>
                  <Col md={{ span: 6 }}>
                    {report.unit_informations
                      ? report.unit_informations.map((info, i) => (
                          <div className='group'>
                            <div className='title-inspek'>{info.label}</div>
                            <div className='value-inspek'>
                              {info.value || '-'}
                            </div>
                          </div>
                        ))
                      : ''}
                    {report.unit_documents
                      ? report.unit_documents.map((document, i) => (
                          <div className='group'>
                            <div className='title-inspek'>{document.label}</div>
                            <div className='value-inspek'>
                              {document.value || '-'}{' '}
                              {document?.additional_value !== null
                                ? ` (${document?.additional_value})`
                                : ''}
                            </div>
                          </div>
                        ))
                      : ''}
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          {report?.unit_inspection?.result_inspection_categories
            ? report.unit_inspection.result_inspection_categories.map(
                (category, i) => {
                  return (
                    <section
                      className='inspeksi'
                      id={
                        category.inspection_category_name
                          ? category.inspection_category_name
                              .toLowerCase()
                              .replace(/[^\w ]+/g, '')
                              .replace(/ +/g, '-')
                          : '-'
                      }
                      ref={(element) =>
                        (inspeksiRef.current[
                          category.inspection_category_name
                            .toLowerCase()
                            .replace(/[^\w ]+/g, '')
                            .replace(/ +/g, '-')
                        ] = element)
                      }
                    >
                      <Container>
                        <div className='box-border'>
                          <h3 className='title-section'>
                            {category.inspection_category_name || '-'}
                          </h3>
                          <Row>
                            {category.result_inspection_subcategory
                              ? category.result_inspection_subcategory.map(
                                  (sub_category, sub) => {
                                    return (
                                      <Col md={{ span: 6 }}>
                                        {sub_category.result_inspection.length >
                                        0 ? (
                                          <div className='subtitle'>
                                            {sub_category.name || '-'} :
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        {sub_category.result_inspection
                                          ? sub_category.result_inspection.map(
                                              (data, ins) => {
                                                return (
                                                  <div className='group flex-column result-inspek'>
                                                    <div className='d-flex justify-content-between w-100'>
                                                      <div>
                                                        {`${ins + 1} `}
                                                        {
                                                          data.inspection_item_name
                                                        }
                                                      </div>
                                                      {/* {data.comment_name === "OK" ? <Success /> : <Warning />} */}
                                                      <div className='border rounded px-2'>
                                                        {data?.comment_name ||
                                                          '-'}
                                                      </div>
                                                    </div>
                                                    {data?.image_url !==
                                                    null ? (
                                                      <div className='images'>
                                                        <Image
                                                          src={data.image_url}
                                                          alt=''
                                                          className='image'
                                                          parentClassName='image-inspeksi cursor-pointer'
                                                          onClick={() =>
                                                            openModal(
                                                              data.image_url
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )
                                          : ''}
                                      </Col>
                                    );
                                  }
                                )
                              : ''}
                          </Row>
                        </div>
                      </Container>
                    </section>
                  );
                }
              )
            : ''}
        </div>
      ) : (
        <div className='py-5 font-sm text-secondary text-center'>
          Unit belum di Inspeksi
        </div>
      )}

      <ModalLayout show={modalState?.show} handleClose={closeModal}>
        <div className='text-center' style={{ height: 'fit-content' }}>
          <img
            alt=''
            src={modalState?.data}
            className='disable-copy h-100'
            onError={imgError}
            loading='lazy'
            style={{ width: '100%' }}
          />
        </div>
      </ModalLayout>
    </>
  );
}
